import React, { useEffect, useState} from "react"
import NavBar from "../components/navbar";
import FloatingActionButton from "../components/fab";
import { useParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
/* eslint-disable no-unused-vars */

import { Authentication, database } from "../firebase-config";
import { onAuthStateChanged} from "firebase/auth";
import { ref, child, get, set, push, serverTimestamp, runTransaction } from "firebase/database";
import { toast } from 'react-toastify';
import Modal from "react-modal";
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAddShoppingCart } from 'react-icons/md';
import ClientDatabase from "../firebase-config-client";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(21, 23, 25, 0.9)'
      },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60%',
        backgroundColor: '#27282c',
        borderRadius: 10,
        border: 'none',
        paddingTop: 60
    },
  };

function AddOrder(){

    const navigate = useNavigate();
    const {client_id, client_uid} = useParams();


    const [clientName, setClientName] = useState("");
    const [clientUID, setClientUID] = useState("");
    const [addOrderModalIsOpen, setAddOrderModalIsOpen] = useState(false);
    const [clientManager, setClientManager] = useState("+1");
    const [clientRoles, setClientRoles] = useState("")

    const [orderServiceOptions, setOrderServiceOptions] = useState("none");
    const [menuOptions, setMenuOptions] = useState("none");
    const [orderNotes, setOrderNotes] = useState("");
    const [itemPrice, setItemPrice] = useState(0);

    const [addOrderSuccess, setAddOrderSuccess] = useState(false);


    const [addOrderDescription, setAddOrderDescription] = useState("");
    const [addOrderArray, setAddOrderArray] = useState([]);

    let localCart_store = localStorage.getItem("cart");



    useEffect(() => {
        //localStorage.removeItem("cart");
        let localCart = JSON.parse(localCart_store);
        //load persisted cart into state if it exists
        if (localCart) setAddOrderArray(localCart);

        getRoles();
        //getCart();

        if(addOrderSuccess){
            toast.success("Order Added Successfully!")
            localStorage.removeItem("cart");
            navigate(`/${client_id}/${client_uid}/orders`)

        }
    },[addOrderSuccess])

    // const getCart = () => {
    //     onAuthStateChanged(Authentication, (user) => {

    //         const dbRef = ref(ClientDatabase(client_id, client_uid));
    //     get(child(dbRef, `/team`)).then((snapshot) => {
    //     if (snapshot.exists()) {
    //         console.log(snapshot.val());
    //         let team = snapshot.val();
    //         for(var x in team) {
    //             let cart = team[x].cart;
    //             if(team[x].uid == user.uid){
    //                 setUserCart(team[x].cart)
    //                 if(Object.keys(cart).length > 0){
    //                     //console.log("here")
    //                     let temp = []
    //                     Object.keys(cart).forEach((key) => {
    //                         temp.push(team[x].cart[key])
    //                         //temp[key] = team[x].cart[key]
    //                     })
    //                     console.log(temp)
    //                     setAddOrderArray(temp)
    //                 }
    //           }
    //         }


    //     } else {
    //         console.log("No data available");
    //     }
    //     }).catch((error) => {
    //     console.error(error);
    //     });

    //     });
        
    // }


    const getRoles = (e) => {
        const dbRef = ref(ClientDatabase(client_id, client_uid));
        get(child(dbRef, `/roles`)).then((snapshot) => {
            if (snapshot.exists()) {
                //console.log(snapshot.val());
                let team = snapshot.val().team;
                setClientRoles(snapshot.val());
    
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            });
    
    }


    const handleSubmit = async (e) =>{
        let user = Authentication.currentUser;
        console.log(user.uid);
        if(user){
                const dbRef = ref(database);
                get(child(dbRef, `admins/${user.uid}`)).then((snapshot) => {
                  if (snapshot.exists()) {
                    //console.log(snapshot.val());
                    //user is admin
                    set(ref(database, 'clients/' + clientUID), {
                        name: clientName,
                        team:{ [clientManager] :{role:"owner", uid:""}},
                        roles: {manager:"manager"}
                    })
                    .then(() => {
                    // Data saved successfully!
                    navigate(`/admin/clients`);
                    })
                    .catch((error) => {
                    // The write failed...
                    });

                  } else {
                    console.log("How did you get here?");
                    //navigate(`/admin`);
                  }
                }).catch((error) => {
                  console.error(error);
                });

        }
    } 

    const capitalize = (s) =>
      {
          return s && s[0].toUpperCase() + s.slice(1);
      }

    const RenderAddOrderServiceOptions = () => {

        let displayed = []
        Object.keys(clientRoles).forEach((role) => {
            //console.log(key, clientTeam[key].role);
            if(role != "manager" && role != "owner"){
            displayed.push(
                <option key={role} value={role}>{capitalize(role)}</option>)
            }
        });

        return(
            <div>
                <select value={orderServiceOptions} onChange={(e) => setOrderServiceOptions(e.target.value)} style={{width: "100%", height: 50, borderRadius: 5, backgroundColor:"#eb5228", color: "white", borderColor: "#eb5228"}}>
                    <option value="none" defaultValue={"none"} disabled hidden>Select an Option</option>
                    {displayed}
                </select>
                
            </div>
        )

    }

    const RenderCart = () => {

        if(Object.keys(addOrderArray).length == 0){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Cart is Empty...</h3>
                        </div>
                    </div>
                </div>
            )
        }else{
                let displayed = []
                Object.keys(addOrderArray).forEach((key) => {
                    //console.log(key, clientTeam[key].role);

                    displayed.push(
                        <div key={key} className="card shadow-1" style={{height:40, padding:5, justifyContent:"center"}}>
                            <li>
                                <p style={{paddingLeft:20, margin:0, fontSize:15, top:10, position:"absolute"}}>{addOrderArray[key].lineItem}</p>
                                <a onClick={(e) => removeLineItem(key)}><input style={{right:15, top:"0px", position:"absolute", justifyContent:"center", height: 25, width: 80, padding: 0, marginTop:7 }}type="submit" value="Delete"/></a>
                            </li>
                        </div>)
                  });
    
                return(
                    <div>
                        {displayed}
                        <div className="links">
                        </div>
                        <button className="btn" type="submit" onClick={addOrderWrapper}>Submit</button>
                    </div>
                )
            }    
    }

    const RenderMenu = () => {

        if(orderServiceOptions == "none"){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Select a service to view the menu...</h3>
                        </div>
                    </div>
                </div>
            )
        }
        else if(clientRoles.length == 0){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Still Loading Data...</h3>
                        </div>
                    </div>
                </div>
            )

        }
        else if(clientRoles[orderServiceOptions].menu == null){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>ERROR: No Menu Found</h3>
                        </div>
                    </div>
                </div>
            )

        }
        else{
            let displayed = []
            Object.keys(clientRoles[orderServiceOptions].menu).forEach((key) => {
                //console.log(key, clientTeam[key].role);
                let price = clientRoles[orderServiceOptions].menu[key].price;
                //setItemPrice(price);
                displayed.push(
                    <option key={key} value={[key,price]}>{capitalize(key)}{(clientRoles[orderServiceOptions].menu[key].price != null ? " $" + clientRoles[orderServiceOptions].menu[key].price :null)}</option>
                )
            });
    
            return(
                <div>
                    <select value={menuOptions} onChange={(e) => setMenuOptions(e.target.value)} style={{width: "100%", height: 50, borderRadius: 5, backgroundColor:"#eb5228", color: "white", borderColor: "#eb5228"}}>
                        <option value="none" defaultValue={"none"} disabled hidden>Select an Option</option>
                        {displayed}
                    </select>
                    
                </div>
            )
            }    
    }

    const removeLineItem_ = (key) => {
        let temp = addOrderArray;
        delete temp[key];
        setAddOrderArray(temp);
    }

    const removeLineItem = (itemID) => {

        let removed = false
  
        //create cartCopy
        let cartCopy = [...addOrderArray]
        
        //cartCopy = cartCopy.filter(item => item.ID != itemID);
        console.log(itemID)

       
        // Object.keys(cartCopy).forEach((key) => {
        //     if(!removed){
        //         //console.log(cartCopy[key].lineItem)
        //         //console.log(Authentication.currentUser.uid)
        //         if(cartCopy[key].lineItem == itemID && cartCopy[key].requestedBy == Authentication.currentUser.uid){
                    
        //             removed = true;
        //         }
        //     }
        // })

        //delete cartCopy[itemID];

        cartCopy.splice(itemID, 1);

       // cartCopy = cartCopy.filter(item => (item.lineItem != itemID && item.requestedBy != Authentication.currentUser.uid));
        
        //update state and local
        setAddOrderArray(cartCopy);
        
        let cartString = JSON.stringify(cartCopy)
        console.log(cartString)
        localStorage.setItem('cart', cartString)

        toast.success("Item Removed From Cart")
      }

    //requested, in progress, ready, completed , cancelled

    const softAddOrder_ = (e) => {
        e.preventDefault();
        let temp = addOrderArray;
        let optionSelected = menuOptions.split(",");
        let lineItem = optionSelected[0];
        let price = optionSelected[1];
        temp.push({
            lineItem : lineItem, 
            notes : orderNotes,
            price: price,
            requestedBy : Authentication.currentUser.uid,
            service: orderServiceOptions,
            status: "requested"
        }) 
        setAddOrderArray(temp);
        setOrderServiceOptions("none");
        setMenuOptions("none");
        setOrderNotes("");
        setItemPrice(0);
        closeAddOrderModal();
        //console.log(serverTimestamp());
    }

    const softAddOrder = (item) => {

        onAuthStateChanged(Authentication, (user) => {
            if (user) {
                //let temp = addOrderArray;
                let optionSelected = menuOptions.split(",");
                let lineItem = optionSelected[0];
                let price = optionSelected[1];
                //setClient_uid(user.uid)
                let cartItem = {
                    lineItem : lineItem, 
                    notes : orderNotes,
                    price: price,
                    requestedBy : Authentication.currentUser.uid,
                    service: orderServiceOptions,
                    status: "requested"
                }

                //create a copy of our cart state, avoid overwritting existing state
                let cartCopy = [...addOrderArray];
                
                
                cartCopy.push(cartItem)

                console.log(cartCopy)
                
                //update app state
                setAddOrderArray(cartCopy)
                
                //make cart a string and store in local space
                let stringCart = JSON.stringify(cartCopy);
                localStorage.setItem("cart", stringCart)

                //setAddOrderArray(temp);
                setOrderServiceOptions("none");
                setMenuOptions("none");
                setOrderNotes("");
                setItemPrice(0);
                closeAddOrderModal();
                //console.log(serverTimestamp());

                toast.success(`${item} Added to Cart`)
        
                //console.log(localStorage.getItem("cart"))
                
            } else {
                // No user is signed in.
            }
        })

  
    }

    const addOrder = async (e) => {
        e.preventDefault();
        //const readableOrderIdRef = ref(database, );
        //console.log(1)
        let success, result;

        for(let i = 0; i < addOrderArray.length; i++){
            //New Attempt
            //console.log(2)
            
            const newdbRef = ref(ClientDatabase(client_id, client_uid), `/totalOrders`);
            runTransaction(newdbRef, (value) => {
                if (value) {
                    value++;
                    const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders`);
                    const newOrderRef = push(orderRef);
                    //console.log(String(Date.now()));
                    set(newOrderRef, {
                        readableOrderId: "1R"+value+String(Date.now()).slice(-3),
                        lineItem : addOrderArray[i].lineItem, 
                        notes : addOrderArray[i].notes,
                        price: parseFloat(addOrderArray[i].price),
                        requestedBy : addOrderArray[i].requestedBy,
                        service: addOrderArray[i].service,
                        status: addOrderArray[i].status,
                        timestamp: serverTimestamp()
                    }).then((promise) => {
                        // Data saved successfully!
                        setAddOrderSuccess(true);
                      })
                      .catch((error) => {
                        // The write failed...
                        toast.error("Error adding order! ERROR: " + error);
                        //setAddOrderSuccess(3);
                      });;
                }
                return value;
            });
        }

        //const result_wait = await result;

        console.log(addOrderSuccess)
        // if (success){
        //     toast.success("Order added successfully!");
        //     navigate(`/${client_id}/${client_uid}/orders`)
        //     localStorage.removeItem("cart");
        // }
    }

    const addOrderWrapper = async (e) => {
        e.preventDefault();
      
        if (addOrderArray.length > 0){
           await addOrder(e);
           //console.log("done")
        } else {
            toast.error("Please add an item to your cart")
        }
    }

    function openAddOrderModal() {setAddOrderModalIsOpen(true);}
    function afterOpenAddOrderModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }
    function closeAddOrderModal() {setAddOrderModalIsOpen(false);}


  return (
    <>
     <NavBar />
     <Modal
        isOpen={addOrderModalIsOpen}
        onAfterOpen={afterOpenAddOrderModal}
        onRequestClose={closeAddOrderModal}
        style={customStyles}
        contentLabel="Example Modal"
        >
        <button className="btn" type="submit" onClick={closeAddOrderModal} style={{width:50, height: 20, padding:0, right:10, top:0, position: "absolute"}}>close</button>
        <h2 style={{color: '#eb5228'}}>Add Order</h2>
            <div className="inputBox">
                <p style={{color: '#eb5228'}}>Service</p>
                <RenderAddOrderServiceOptions />
            </div>
            <div className="inputBox">
                <p style={{color: '#eb5228'}}>Menu</p>
                <RenderMenu />
            </div>
            <div className="inputBox">
                <input type="select" required onChange={(e) => setOrderNotes(e.target.value)}/>
                <span>Notes</span>
                <i></i>
            </div>
            <div className="links">
            </div>
            <button className="btn" type="submit" style={{marginTop: 60}} onClick={softAddOrder}>Add</button>
      </Modal>
     <div className="container">
        <div className="register_container " >
        <h2 style={{color: '#eb5228'}}>Order Cart</h2>
            <RenderCart />
        </div>
    </div>
    <Fab
      //mainButtonStyles={mainButtonStyles}
      icon={<MdAddShoppingCart />}
      event={'click'}
      alwaysShowTitle={true}
      mainButtonStyles={{ backgroundColor: "#eb5228", bottom: 0, right: 0 }}
      onClick={(e) => openAddOrderModal()}
      >
      </Fab>
    </>
        
  )
}

export default AddOrder