import { useParams, useNavigate } from "react-router-dom";
import { Authentication } from "../firebase-config";
import {useState, useEffect} from "react"
import {onAuthStateChanged} from "firebase/auth";
import { ref, child, get } from "firebase/database";
import ClientDatabase from "../firebase-config-client";



function ManagerCheck() {

  const { client_id , client_uid} = useParams();
  const navigate = useNavigate();
  const [refreshCount, setRefreshCount] = useState(0);


  //const user = Authentication.currentUser;

  useEffect(() => {

    if(refreshCount <= 1){
      onAuthStateChanged(Authentication, (user) => {
        if (user) {
            userManager_auto(user.uid)
        } else {
          console.log("User is not signed in");
        }
      });
    }

  },[])



  const userManager_auto = (uuid) => {

    const dbRef = ref(ClientDatabase(client_id, client_uid));
    setRefreshCount(refreshCount + 1);
    get(child(dbRef, `/team`)).then((snapshot) => {
    if (snapshot.exists()) {
        //console.log(snapshot.val());
        let team = snapshot.val();

        for(var x in team) {
          if(team[x].uid === uuid && team[x].role!=="manager" && team[x].role!=="owner") {
              navigate(`/${client_id}/${client_uid}/login`);
          }
        }
        
    } else {
        console.log("No data available");
    }
    }).catch((error) => {
    console.error(error);
    });
  }
  
}

export default ManagerCheck;