import { useEffect, useState } from "react"
import { database } from '../firebase-config';
import { doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";

import { ref, child, get } from "firebase/database";



function ClientIdentifier(){

    const {client_id} = useParams();
    const navigate = useNavigate();
    const [refreshCount, setRefreshCount] = useState(0);


    useEffect(() => {
            getClients();
    },[])

    const getClients = (e) => {

        const dbRef = ref(database);
        get(child(dbRef, `clients/${client_id}`)).then((snapshot) => {
        if (snapshot.exists()) {
            //console.log(snapshot.val());
            navigate(`/${client_id}/${snapshot.val().uid}`)
        } else {
            // doc.data() will be undefined in this case
            console.log("No client document!");
            navigate("/client/404")
        }
        }).catch((error) => {
        console.error(error);
        });

    }

}

export default ClientIdentifier;