/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from "react"
import NavBar from "../components/navbar";
import FloatingActionButton from "../components/fab";
import { useParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';
import ClientDatabase from "../firebase-config-client";

import { Authentication, database } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { getDatabase, ref, child, get, set, push, serverTimestamp, increment, update, runTransaction } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAddShoppingCart } from 'react-icons/md';

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(21, 23, 25, 0.9)'
      },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60%',
        backgroundColor: '#27282c',
        borderRadius: 10,
        border: 'none',
        paddingTop: 60
    },
  };

function OrderDetails(){

    const navigate = useNavigate();
    const {client_id, client_uid, order_id} = useParams();


    const [orderDetails, setOrderDetails] = useState({});

    const [clientName, setClientName] = useState("");
    const [clientUID, setClientUID] = useState("");
    const [addOrderModalIsOpen, setAddOrderModalIsOpen] = useState(false);
    const [clientManager, setClientManager] = useState("+1");
    const [clientRoles, setClientRoles] = useState("")

    const [orderServiceOptions, setOrderServiceOptions] = useState("none");
    const [menuOptions, setMenuOptions] = useState("none");
    const [orderNotes, setOrderNotes] = useState("");
    const [orderTotal, setOrderTotal] = useState(0);
    const [itemPrice, setItemPrice] = useState(0);
    const [itemName, setItemName] = useState("");


    const [addOrderDescription, setAddOrderDescription] = useState("");
    const [addOrderArray, setAddOrderArray] = useState([]);


    useEffect(() => {
        getOrder();
    },[])

    const getOrder = () => {

      const dbRef = ref(ClientDatabase(client_id, client_uid));
      get(child(dbRef, `/orders/${order_id}`)).then((snapshot) => {
        if (snapshot.exists()) {
          setOrderDetails(snapshot.val());
        } else {
          console.log("Error: No Order found");
        }
      }).catch((error) => {
        console.error(error);
      });
  }

  const capitalize = (s) =>
  {
      let output = ""
      let words = s.split(" ");
      for(let word in words){
          output += words[word][0].toUpperCase() + words[word].slice(1) + " ";
      }
      return output
  }





  return (
    <>
     <NavBar />
     <div className="container">
        <div className="register_container">
        <h2 style={{color: '#eb5228'}}>Order Details</h2>
        <table style={{color:"white", width:"100%"}}>
          <tr>
              <td>
              <h3>{orderDetails.readableOrderId}</h3>
              </td>
          </tr>
          <tr>
            <td>
              <h3>{capitalize(orderDetails.service)}</h3>
            </td>
            <td>
              <h3>{capitalize(orderDetails.status)}</h3>
            </td>
          </tr>
          <tr>
            <td>
              <h3>{capitalize(orderDetails.lineItem)}</h3>
            </td>
          </tr>
          <tr>
            <td>
              <h3>{orderDetails.price}</h3>
            </td>
          </tr>
          {orderDetails.notes!=""?null:<tr>
            <td>
              <h3>{orderDetails.notes}</h3>
            </td>
          </tr>}
        </table>
           
            
        </div>
    </div>
    </>
        
  )
}

export default OrderDetails