import { signOut } from "firebase/auth";
import { Authentication } from "../firebase-config";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function LogOut() {

    //const { client_id } = useParams();
    //const navigate = useNavigate();

    signOut(Authentication).then(() => {
        // Sign-out successful.
        toast.success("Logged Out Successfully")
       //navigate(`/${client_id}/login`);
      }).catch((error) => {
        toast.error(error.message,{
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            });
      });
}
