import React from "react"
import '../css/loading.css';

function Loading(props){

    if(props.isLoading === true){
        return (
            <div id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
            </div>  
          )
    }
}

export default Loading