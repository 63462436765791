/* eslint-disable no-unused-vars */
import React from "react"
import NavBar from "../../components/navbar";
import ManagerAddMember from "../../manager/pages/managerAddMember";

function ClientManagerAddMember(){

  return (
    <>
     <NavBar />
     <ManagerAddMember />
    </>
        
  )
}

export default ClientManagerAddMember