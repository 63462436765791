import React from "react"
import NavBar from "../components/navbar";
import FloatingActionButton from "../components/fab";
/* eslint-disable no-unused-vars */





import { Authentication } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { db } from '../firebase-config';
import { collection, doc, getDoc, getDocs } from "firebase/firestore";




function ManageWorkers(){

  return (
    <>
     <NavBar />
     <div>welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br />welcome home <br /></div>
     <FloatingActionButton />
    </>
        
  )
}

export default ManageWorkers