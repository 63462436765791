/* eslint-disable no-unused-vars */
import React, {useState, useEffect}from "react"
import AdminNavBar from "../adminNavbar";
import FloatingActionButton from "../../components/fab";
import { useParams, useNavigate } from "react-router-dom";
import "../../css/listSearch.css"


import { Authentication, database } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { getDatabase, ref, query, child, get } from "firebase/database";




function AdminClientList(){

    const [clients_state, setClients_state] = useState([]);
    const [refreshCount, setRefreshCount] = useState(0);
    const [clientID_rendered, setClientID_rendered] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [displayedContacts, setDisplayedContacts] = useState([]);

    const clients = []
    const dynamic_routes = []
    const {client_id} = useParams();
    const navigate = useNavigate();


    const getClients = (e) => {

        const dbRef = ref(database);
        get(child(dbRef, "clients/")).then((snapshot) => {
            if (snapshot.exists()) {
                setRefreshCount(refreshCount + 1);
                //console.log(snapshot.val());
                for(let client in snapshot.val()){
                    let clientName = snapshot.val()[client].name ;
                    let clientID = client
                    let clientUID = snapshot.val()[client].uid;
                    //console.log("clientUID: " + clientUID);
                    clients.push({name: clientName, uid: clientUID, id: clientID});
                }
                setClients_state(clients);
            } else {
              console.log("No records found");
            }
          }).catch((error) => {
            console.error(error);
          });

        //const querySnapshot = query(ref(database, 'clients/'), orderByChild('name'));
        // querySnapshot.forEach((doc) => {
        //     let clientName = doc.val().name ;
        //     let clientUID = doc.key;
        //     clients.push({name: clientName, id: clientUID});
        // });
        //console.log(clients);


    }

    useEffect(() => {
        if(refreshCount < 1){
            getClients();
        }
        
    },[])


    const RenderClients = () => {
    let input, filter, ul, li, a, i, c_name, c_id, c_uid;

        
    filter = searchQuery.toUpperCase();
    

    if(clients_state.length == 0){
        return(
            <div className="client_list_container">
                <div className="client_list">
                    <div className="client_list_item">
                        <h3>Loading</h3>
                    </div>
                </div>
            </div>
        )
    }else{
            let displayed = []
            let nameHolder =[]
            for (i = 0; i < clients_state.length; i++) {
                //console.log(clients_state[i].name + " " + i)
                c_name = clients_state[i].name
                c_id = clients_state[i].id
                c_uid = clients_state[i].uid
                //console.log(c_uid)
                if (c_name.toUpperCase().indexOf(filter) > -1 && !nameHolder.includes(c_name)) {
                    nameHolder.push(c_name)
                    //console.log("id: " + c_id)
                    displayed.push(
                        <div key={c_id} className="card shadow-1">
                            <li>
                                <span>{c_name}</span>
                                <a href={`/admin/clients/${c_id}/${c_uid}`}><input style={{right:0, float: "right",}}type="submit" value="Select"/></a>
                            </li>
                        </div>
                    )
                } 
            }

            return(
                <div>
                    {displayed}
                </div>
            )
        }    
    }


    const searchHandler = () => {
        
        let searcjQery = searchQuery.toLowerCase(),
        displayedContacts = clients_state.filter((el) => {
              let searchValue = el.name.toLowerCase();
              return searchValue.indexOf(searcjQery) !== -1;
            })
        setDisplayedContacts(displayedContacts)
      }



  return (
    <>
     <AdminNavBar />
      <div className="search_container">
        <input type="text" className="list_search" placeholder="Search..." onChange={(e) => setSearchQuery(e.target.value)}/>
        <ul className="list_holder">
            <RenderClients />   
        </ul>
      </div>     
     <FloatingActionButton />
    </>
        
  )
}

export default AdminClientList