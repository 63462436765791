import { useParams, useNavigate } from "react-router-dom";
import { Authentication } from "../firebase-config";
import {useState,useEffect} from "react"
import {onAuthStateChanged} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { database } from '../firebase-config';

import { getDatabase, ref, child, get } from "firebase/database";



function AdminCheck() {

  const { client_id } = useParams();
  const navigate = useNavigate();
  const [refreshCount, setRefreshCount] = useState(0);


  //const user = Authentication.currentUser;

  useEffect(() => {

    if(refreshCount <= 1){
      onAuthStateChanged(Authentication, (user) => {
        if (user) {
            adminDBcheck_auto(user.uid)
        } else {
          console.log("User is not signed in");
          navigate(`/admin`);
        }
      });
    }

  },[])



  const adminDBcheck_auto = async (uuid) => {
    const dbRef = ref(database);
    setRefreshCount(refreshCount + 1)
    get(child(dbRef, `admins/${uuid}`)).then((snapshot) => {
      if (snapshot.exists()) {
        console.log(snapshot.val());
      } else {
        console.log("No data available");
        navigate(`/admin`);
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  
}

export default AdminCheck;