import { useParams, useNavigate } from "react-router-dom";
import { Authentication } from "../firebase-config";
import {useEffect} from "react"
import {onAuthStateChanged} from "firebase/auth";

function LoginCheck() {

  const { client_id, client_uid } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    _check();
},[])

  //const user = Authentication.currentUser;

  const _check = () => {
    onAuthStateChanged(Authentication, (user) => {
        if (user) {
          // User is signed in, see docs for a list of available properties
          // https://firebase.google.com/docs/reference/js/firebase.User
          const uid = user.uid;
          // ...
          //console.log("User is signed in");
          //console.log(uid);
        } else {
          // User is signed out
          // ...
          console.log("User is not signed in");
                navigate(`/${client_id}/${client_uid}/login`);
        }
      });
  }

  
}

export default LoginCheck;