/* eslint-disable no-unused-vars */

import React, { useEffect, useState} from "react"
import FloatingActionButton from "../components/fab";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import firebase from 'firebase/compat/app';

import { Authentication, database } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { getDatabase, ref,onValue, child, get, set, push, serverTimestamp, increment, update, runTransaction } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAdd, MdCreate, MdCodeOff ,MdAddTask } from "react-icons/md";
import ManagerCheck from "../manager/managerCheck";
import { getDefaultEmulatorHost } from "@firebase/util";
import ClientDatabase from "../firebase-config-client";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(21, 23, 25, 0.9)'
      },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60%',
        backgroundColor: '#27282c',
        borderRadius: 10,
        border: 'none',
        paddingTop: 60
    },
  };

function MenuServices(){

    const navigate = useNavigate();
    const currentPath = useLocation().pathname;
    const {client_id, role, client_uid} = useParams();



    const [clientName, setClientName] = useState("");
    const [clientUID, setClientUID] = useState("");
    const [addOrderModalIsOpen, setAddOrderModalIsOpen] = useState(false);
    const [clientManager, setClientManager] = useState("+1");
    const [clientRoles, setClientRoles] = useState([])
    const [clientTeam, setClientTeam] = useState([])
    const [userRole,   setUserRole] = useState("")
    const [uuid, setUUID] = useState("")
    const [userName,   setUserName] = useState("")
    const [openAddMenuItemModal, setOpenAddMenuItemModal] = useState(false)

    const [orderServiceOptions, setOrderServiceOptions] = useState("none");
    const [menuOptions, setMenuOptions] = useState("none");
    const [orderNotes, setOrderNotes] = useState("");
    const [orderTotal, setOrderTotal] = useState(0);
    const [itemPrice, setItemPrice] = useState(0);
    const [itemName, setItemName] = useState("");


    const [addOrderDescription, setAddOrderDescription] = useState("");
    const [addOrderArray, setAddOrderArray] = useState([]);


    useEffect(() => {
        onAuthStateChanged(Authentication, (user) => {
            if (user) {
                setUUID(user.uid)
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // ...
                getRoles();
                //getTeam();
            } else {
                // User is signed out
                // ...
                //console.log("User is signed out");
                //navigate("/login");
            }
        })
        
    },[])

    const getRoles = () => {

        const dbRef = ref(ClientDatabase(client_id, client_uid));
        get(child(dbRef, `/roles`)).then((snapshot) => {
          if (snapshot.exists()) {
            setClientRoles(snapshot.val());
          } else {
            console.log("Error: No team found");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    const getTeam = () => {

    const dbRef = ref(ClientDatabase(client_id, client_uid));
      get(child(dbRef, `/team`)).then((snapshot) => {
        if (snapshot.exists()) {
          setClientTeam(snapshot.val());
          let team = snapshot.val()
            for(var x in team) {
                if(team[x].uid == Authentication.currentUser.uid) {
                    setUserRole(team[x].role)
                    setUserName(team[x].name)
                    window.localStorage.setItem('user_role', team[x].role);
                }
            }
        } else {
          console.log("Error: No team found");
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    const capitalize = (s) =>
    {
      console.log(s)
        // let output = ""
        // let words = s.split(" ");
        // for(let word in words){
        //   if(typeof word != "undefined"){
        //     output += words[word][0].toUpperCase() + words[word].slice(1) + " ";}
        // }
        const finalString = s.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
        //return output

        return finalString
    }

    const handleNav = (key) => {
        navigate(`${currentPath}/${key}`)
    }


    const RenderMenu = (e) => {

        if(Object.keys(clientRoles).length == 0){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Loading...</h3>
                        </div>
                    </div>
                </div>
            )
        }else{
                let displayed = []
                Object.keys(clientRoles).forEach((key) => {
                  
                    //console.log(key, clientTeam[key].role);
                    if (key != "owner" && key != "manager"){
                        displayed.push(
                            <div key={key} className="card shadow-1" onClick={() => handleNav(key)}>
                                <li>                              
                                    <div style={{height: "100%", lineHeight: "60px", alignItems:"center", textAlign:"center", }}>
                                      <span style={{fontSize:30, color:"#eb5228", textAlign:"center", display:"inline-block", verticalAlign:"middle",lineHeight: "normal"}}>{capitalize(key)}</span>
                                    </div> 
                                </li>
                            </div>
                            )
                    

                    }

                  });

                  return(
                    <div style={{paddingTop:5}}>
                        {displayed}
                    </div>
                )

            
            


    
                
        }  



    }


    function doopenAddMenuItemModal() {setOpenAddMenuItemModal(true);}
    function afterOpenRoleModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }
    function d0closeAddMenuItemModal() {setOpenAddMenuItemModal(false);}

    const RenderMenuAdd = (e) => {
            
            let showAlllMenus = false
    
            if (userRole === "manager" || userRole === "owner" || role === userRole){
               // ManagerCheck();
                return(
                    <>
                            <Fab
                                //mainButtonStyles={mainButtonStyles}
                                icon={<MdAdd />}
                                event={'click'}
                                alwaysShowTitle={true}
                                mainButtonStyles={{ backgroundColor: "#eb5228", bottom: 0, right: 0 }}
                                onClick={doopenAddMenuItemModal}
                                >
                            </Fab>
                    </>
                )
            }
    }





  return (
    <>
     <div className="container">
        <div className="register_container">
        <h2 style={{color: '#eb5228'}}>Menu</h2>
           <RenderMenu />
        </div>
    </div>
    </>
        
  )
}

export default MenuServices