/* eslint-disable no-unused-vars */
import React from "react"
import NavBar from "../../components/navbar";
import ManagerDashboard from "../../manager/pages/managerDashboard";






function ClientManagerDashboard(){

  return (
    <>
     <NavBar />
     <ManagerDashboard/>
    </>
        
  )
}

export default ClientManagerDashboard