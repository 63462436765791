import './App.css';
import React from "react"
import { BrowserRouter, Routes, Route, MemoryRouter } from "react-router-dom";
import Landing from './pages/landing';

import Login from './auth/login';
import Register from './auth/register';
import Dashboard from './pages/dashboard';
import ClientNotFound from './404_client';
import ClientIdentifier from './auth/clientIdentifier';
import ManageWorkers from './pages/manageWorkers';
//import Orders from './pages/orders';
import ClientOrders from './client/pages/clientOrders';
import AddOrder from './pages/addOrder';
import OrderDetails from './pages/orderDetails';
import ClientMenu from './client/pages/clientMenu';
import ClientMenuServices from './client/pages/clientMenuServices';

import AdminAuth from './admin/adminLogin';
import AdminDashboard from './admin/pages/adminDashboard';
import AdminClientOnboarding from "./admin/onboarding/onboarding";
import AdminClientList from "./admin/pages/adminClients";
import AdminManageClient from './admin/pages/adminManageClient';

import AdminManagerManageTeam from './admin/pages/adminManagerManageTeam';
import AdminManageMenu from './admin/pages/adminManageMenu';
import AdminManageMenuServices from './admin/pages/adminManageMenuServices';

import ClientManagerManageTeam from './client/manager/clientManagerManageTeam';
import ClientManagerDashboard from './client/manager/clientManagerDashboard';
import ClientManagerAddMember from './client/manager/clientManagerAddMember';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Landing/>}/>

        <Route path="/:client_id" element={<ClientIdentifier/>}/>
        <Route path="/:client_id/:client_uid" element={<Login/>}/>
        <Route path="/:client_id/:client_uid/login" element={<Login/>}/>
        <Route path="/:client_id/:client_uid/register" element={<Register/>}/>
        <Route path="/:client_id/:client_uid/dashboard" element={<Dashboard/>}/>

        <Route path="/:client_id/:client_uid/orders" element={<ClientOrders/>}/>

        <Route path="/:client_id/:client_uid/add-order" element={<AddOrder/>}/>
        <Route path="/:client_id/:client_uid/cart" element={<AddOrder/>}/>
        <Route path="/:client_id/:client_uid/orders/:order_id" element={<OrderDetails/>}/>
        <Route path="/:client_id/:client_uid/menu" element={<ClientMenuServices/>}/>
        <Route path="/:client_id/:client_uid/menu/:service" element={<ClientMenu/>}/>

        
        <Route path="/:client_id/:client_uid/manager/dashboard" element={<ClientManagerDashboard/>}/>
        <Route path="/:client_id/:client_uid/manager/team" element={<ClientManagerManageTeam/>}/>
        <Route path="/:client_id/:client_uid/manager/team/add" element={<ClientManagerAddMember/>}/>

        <Route path="/:client_id/:client_uid/manage-all-users" element={<ManageWorkers/>}/>

        <Route path="/client/404" element={<ClientNotFound/>}/>

        <Route path="/admin" element={<AdminAuth/>}/>
        <Route path="/admin/login" element={<AdminAuth/>}/>
        <Route path="/admin/dashboard" element={<AdminDashboard/>}/>
        <Route path="/admin/onboarding" element={<AdminClientOnboarding/>}/>
        <Route path="/admin/clients" element={<AdminClientList/>}/>
        <Route path="/admin/clients/:client_id/:client_uid" element={<AdminManageClient/>}/>

        <Route path="/admin/clients/:client_id/:client_uid/team" element={<AdminManagerManageTeam/>}/>
        <Route path="/admin/clients/:client_id/:client_uid/menu" element={<AdminManageMenuServices/>}/>
        <Route path="/admin/clients/:client_id/:client_uid/menu/:service" element={<AdminManageMenu/>}/>

        
      </Routes>
    </BrowserRouter>
  )
}

export default App;