/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from "react"
import NavBar from "../../components/navbar";
import { ToastContainer, toast } from 'react-toastify';
import Menu from "../../pages/menu";

function ClientMenu(){

   

  return (
    <>
     <NavBar />
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
     <Menu />
    </>
        
  )
}

export default ClientMenu