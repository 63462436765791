/* eslint-disable no-unused-vars */
import React from "react"
import NavBar from "../../components/navbar";
import ManagerManageTeam from "../../manager/pages/managerManageTeam";

function ClientManagerManageTeam(){

  return (
    <>
     <NavBar />
     <ManagerManageTeam />
    </>
        
  )
}

export default ClientManagerManageTeam