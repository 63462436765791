/* eslint-disable no-unused-vars */
import React,{useEffect, useState}from "react"
import { database } from '../firebase-config';
import { Authentication } from "../firebase-config";
import { doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "./loading";
import 'react-toastify/dist/ReactToastify.css';
import LogOut from "../auth/logout";
import { toast, ToastContainer } from 'react-toastify';

import '../css/navbar.css';

import ClientCheck from "../auth/clientCheck";
import LoginCheck from "../auth/loginCheck";
import UserDbCheck from "../auth/userDBcheck";


import { getDatabase, ref, child, get } from "firebase/database";
import { onAuthStateChanged } from "firebase/auth";
import ClientDatabase from "../firebase-config-client";



function NavBar(){

    ClientCheck();
    LoginCheck();
    UserDbCheck();

    const [isLoading, setIsLoading] = useState(false);
    const [clientName_DB, setClientName_DB] = useState("");
    const [clientID_DB, setClientID_DB] = useState("");
    const [userRole, setUserRole] = useState("");
    const [userName, setUserName] = useState("");
    const [refreshCount, setRefreshCount] = useState(0);



    const {client_id, role, client_uid} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        updateClientInfo()
    },[])


    const updateClientInfo = () => {
        setIsLoading(true);
        onAuthStateChanged(Authentication, (user) => { 
        if(user) {
                
            const dbRef = ref(ClientDatabase(client_id, client_uid));
            setRefreshCount(refreshCount + 1);
            get(child(dbRef, `/name`)).then((snapshot) => {
                if (snapshot.exists()) {
                    //console.log(snapshot.val());
                    setClientName_DB(snapshot.val())
                }})
            get(child(dbRef, `/team`)).then((snapshot) => {
            if (snapshot.exists()) {
                
                
                let team = snapshot.val()

                //console.log(team)

                Object.keys(team).forEach((key) => {
                    let team_uid = team[key].uid
                    //console.log(user.uid)
                    if(team_uid == user.uid) {
                        //console.log(key);
                        //console.log(team[key]);
                        setUserRole(team[key].role)
                        setUserName(team[key].name)
                        //window.localStorage.setItem('user_role', team[x].role);
                }
                });


            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            });

            setIsLoading(false);
        }
    });

    }

    const getClientInfo = () => {
        console.log("DB call saved")
        setClientName_DB(window.localStorage.getItem('client_name'))
        setUserRole(window.localStorage.getItem('user_role'))
    }

  return (
    <>
        <Loading isLoading={isLoading}/>
        <header className="header">
            <a href="" className="logo">{clientName_DB}</a>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            
            <ul className="menu">
                <p style={{color:"#eb5228", fontSize: 14, textAlign: "center"}} >Welcome, {userName}</p>
                {userRole === "owner" || userRole === "manager"
                ? //true
                <>
                    <li><a href={`/${client_id}/${client_uid}/manager/dashboard`}>Dashboard</a></li>
                    <li><a href={`/${client_id}/${client_uid}/orders`}>Orders</a></li>
                    <li><a href={`/${client_id}/${client_uid}/manager/team`}>Manage Team</a></li> 
                    <li><a href={`/${client_id}/${client_uid}/menu`}>Menu</a></li>
                    <li><a href={`/${client_id}/${client_uid}/cart`}>Cart</a></li>
                    <li><a href={`/${client_id}/${client_uid}/profile`}>Profile</a></li>
                    <li><a onClick={LogOut} >Sign Out</a></li>
                </>
                : //false
                <>
                    <li><a href={`/${client_id}/${client_uid}/orders`}>Orders</a></li>
                    <li><a href={`/${client_id}/${client_uid}/menu`}>Menu</a></li>
                    <li><a href={`/${client_id}/${client_uid}/cart`}>Cart</a></li>
                    <li><a href={`/${client_id}/${client_uid}/profile`}>Profile</a></li>
                    <li><a onClick={LogOut} >Sign Out</a></li>
                </>}
            </ul>
        </header>
        <div className="pagePadding">

        </div>
    </>
  )
}

export default NavBar