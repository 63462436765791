/* eslint-disable no-unused-vars */
import React, {useState} from "react"
import { FcAbout, FcBusinessman, FcCamera, FcFullTrash } from "react-icons/fc";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAdd, MdCreate, MdCodeOff ,MdAddTask } from "react-icons/md";
import {IoCreateSharp} from "react-icons/io5";
import { render } from "@testing-library/react";
import { useParams, useNavigate } from "react-router-dom";


function FloatingActionButton({options}){

  const navigate = useNavigate();
  const {client_id, user} = useParams();

  const eventHandler = (rought) => {
    //e.preventDefault();
    console.log("eventHandler");
    navigate(`/${client_id}/add`);
  }


  const ActionRenderer = (e) => {
    //e.preventDefault();
    let displayed = []
    for (let i in options){
      console.log(i);
      displayed.push(
        <Action
          //text= "title"
          style={{ backgroundColor: "#ffffff", width: "100%", borderRadius: 3}}
          children={<p style={{ color: "black", padding: 10}}>{options[i].text}</p>}
          onClick={(e) => options[i].link}
        />
      )
    }

    return(
      <Fab
      //mainButtonStyles={mainButtonStyles}
      icon={<MdAdd />}
      event={'click'}
      alwaysShowTitle={true}
      mainButtonStyles={{ backgroundColor: "#eb5228", bottom: 0, right: 0 }}
      //onClick={someFunctionForTheMainButton}
      >
        {displayed}
      </Fab>
    )
  }
    
  return (
    <>
      <ActionRenderer />
    </>
  );
    
}

export default FloatingActionButton