/* eslint-disable no-unused-vars */
import React from "react"
import NavBar from "../../components/navbar";
import FloatingActionButton from "../../components/fab";
import { useParams, useNavigate } from "react-router-dom";

import ManagerCheck from "../managerCheck"

import { Authentication, database } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { getDatabase, ref, child, get, set } from "firebase/database";
import ClientDatabase from "../../firebase-config-client";

function ManagerAddMember(){

    ManagerCheck();

    const navigate = useNavigate();
    const {client_id,client_uid} = useParams();


    const [clientName, setClientName] = React.useState("");
    const [clientUID, setClientUID] = React.useState("");
    const [phone, setPhone] = React.useState("+1");


    const handleSubmit = (e) =>{
        e.preventDefault()

        let user = Authentication.currentUser;
        console.log(user.uid);

        if(user){

            //console.log(snapshot.val());
            //user is admin
            set(ref(ClientDatabase(client_id, client_uid), `/team/${phone}`), {
                role: "server"
            })
            .then(() => {
            // Data saved successfully!
            navigate(`/${client_id}/${client_uid}/manager/team`);
            })
            .catch((error) => {
            // The write failed...
            });

            

        }
    } 


  return (
    <>
     <div className="container">
        <div className="register_container " >
            <h2 style={{color: '#eb5228'}}>Add Member</h2>
            <div className="inputBox">
                <input type="text" required value={phone} onChange={(e) => setPhone(e.target.value)}/>
                <span>New Member's Phone Number</span>
                <i></i>
            </div>
            <div className="inputBox">
                <input type="text" required onChange={(e) => setPhone(e.target.value)}/>
                <span>Role</span>
                <i></i>
            </div>
            
            
            <div className="links">
            </div>
            <button className="btn" type="submit" onClick={handleSubmit} >Submit</button>
        </div>
    </div>
     <FloatingActionButton />
    </>
        
  )
}

export default ManagerAddMember