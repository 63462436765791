/* eslint-disable no-unused-vars */

import React from "react"
import NavBar from "../../components/navbar";
import MenuServices from "../../pages/menuServices";


function ClientMenuServices(){

  return (
    <>
     <NavBar />
     <MenuServices />
    </>
        
  )
}

export default ClientMenuServices