/* eslint-disable no-unused-vars */
import React from "react"
import NavBar from "../../components/navbar";
import Orders from "../../pages/orders";



function ClientOrders(){

  return (
    <>
     <NavBar />
      <Orders />
    </>
        
  )
}

export default ClientOrders