import React, { useEffect, useState } from "react"
import { database } from '../firebase-config';
import LoginCheck from "./loginCheck";
import { Authentication } from "../firebase-config";
import {onAuthStateChanged} from "firebase/auth";
import { useParams, useNavigate } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';

import { ref, update, get, child } from "firebase/database";


import '../css/Auth.css';
import ClientDatabase from "../firebase-config-client";


function Register() {

    LoginCheck()


    const user = Authentication.currentUser;

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const { client_id, client_uid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        putPhone()
    },[])

    const putPhone = () => {
        onAuthStateChanged(Authentication, (user) => {
            if (user) {
              // User is signed in, see docs for a list of available properties
              // https://firebase.google.com/docs/reference/js/firebase.User
              //const uid = user.uid;
              // ...
              setPhone(user.phoneNumber)
      
            } else {
              // User is signed out, stay
              // ...
              console.log("User is not signed in");
            }
          });
    }
    
    
   //setPhone(user.phoneNumber)

    const handleSubmit = (e) =>{
        e.preventDefault();
        
        if (user) {


            console.log("Starting update");
            //const usersRef = collection(db, "allUsers");
            update(ref(ClientDatabase(client_id,client_uid), `team/${phone}`), {
                name: name.trim(),
                uid: user.uid,
            });

        console.log(user.phoneNumber)
        console.log("done")

        const dbRef = ref(ClientDatabase(client_id, client_uid));
      get(child(dbRef, `/team/${phone}`)).then((snapshot) => {
        if (snapshot.exists()) {

            if (snapshot.val().role == "manager" || snapshot.val().role == "owner"){
                navigate(`/${client_id}/${client_uid}/manager/dashboard`)
              }else{
                console.log("employee")
                navigate(`/${client_id}/${client_uid}/dashboard`);
              }
        } else {
            console.log("No data available");
        }
        }).catch((error) => {
        console.error(error);
        });




        } else {
            console.log("User is not signed in");
            navigate(`/${client_id}/${client_uid}/login`);
        }
        
    }


  return (
    <div className="container">
    <div className="register_container" >
        <h2 style={{color: '#eb5228'}}>REGISTER</h2>
        <div className="inputBox">
            <input type="text" required onChange={(e) => setName(e.target.value)}/>
            <span>Name</span>
            <i></i>
        </div>
        <div className="inputBox">
            <input readOnly type="phone" value={phone} required onChange={(e) => setPhone(e.target.value)}/>
            <a className="register_label" href="javascript">Phone Number</a>
            <a className="register_bkg" href="javascript" > </a>
        </div>
        <div className="links">
        </div>
        <button className="btn" type="submit" onClick={handleSubmit} >Submit</button>
    </div>
</div>
  );
}
  
export default Register