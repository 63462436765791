import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFunctions } from "firebase/functions";
import { useParams } from "react-router-dom";


// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const firebaseConfig = {
  // ...
  // The value of `databaseURL` depends on the location of the database
  databaseURL: "https://oneround-default-rtdb.firebaseio.com/",
  apiKey: "AIzaSyBd_NPh_B9--YKn0eLwxdPXHNKRhSWu1pY",
  authDomain: "oneround.firebaseapp.com",
  projectId: "oneround",
  storageBucket: "oneround.appspot.com",
  messagingSenderId: "1024651262821",
  appId: "1:1024651262821:web:1ef5b782af69f7703e3f8c",
  measurementId: "G-94WXSSBWBK"
};

//console.log(serviceAccount.private_key)

console.log(firebaseConfig.databaseURL)

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
export const database = getDatabase(app);
export const Authentication = getAuth(app);
export const Functions = getFunctions(app);
