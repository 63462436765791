import React from "react"
import Iframe from 'react-iframe'
import "../css/landing.css"


function Landing(){

  return (
    <>
    	<Iframe url="https://oneround-landing.web.app/"
        width="100%"
        height="100%"
        id=""
        className=""
        display="block"
        position="relative"
        styles={{height: "100vh", border: "none", background: "white"}}
        
       />
    
    </>
        
  )
}

export default Landing