/* eslint-disable no-unused-vars */
import React from "react"
import AdminNavBar from "../adminNavbar";
import ManagerManageTeam from "../../manager/pages/managerManageTeam";

function ClientManagerManageTeam(){

  return (
    <>
     <AdminNavBar />
     <ManagerManageTeam />
    </>
        
  )
}

export default ClientManagerManageTeam