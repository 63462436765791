/* eslint-disable no-unused-vars */
import React from "react"
import AdminNavBar from "../adminNavbar";
import FloatingActionButton from "../../components/fab";





import { Authentication, db } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";




function AdminDashboard(){

  return (
    <>
     <AdminNavBar />
     <FloatingActionButton />
    </>
        
  )
}

export default AdminDashboard