import React from "react"
import './css/404_client.css';


function ClientNotFound(){
    return(
        <>
        <div className="container_404">
            <h1 className="neonText">
                404
            </h1>
            <h2 className="neonText">Client Not Found</h2> 
        
        </div>
     </>
    )
}

export default ClientNotFound
