/* eslint-disable no-unused-vars */
import React from "react"
import AdminNavBar from "../adminNavbar";
import Menu from "../../pages/menu";

function AdminManageMenu(){

  return (
    <>
     <AdminNavBar />
     <Menu />
    </>
        
  )
}

export default AdminManageMenu