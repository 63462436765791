import { useParams, useNavigate } from "react-router-dom";
import { Authentication } from "../firebase-config";
import {useState,useEffect} from "react"
import {onAuthStateChanged} from "firebase/auth";
import { ref, child, get } from "firebase/database";
import ClientDatabase from "../firebase-config-client";



function UserDbCheck() {

  const { client_id, client_uid } = useParams();
  const navigate = useNavigate();
  const [refreshCount, setRefreshCount] = useState(0);


  //const user = Authentication.currentUser;

  useEffect(() => {

    if(refreshCount <= 1){
      onAuthStateChanged(Authentication, (user) => {
        if (user) {
          userDBcheck_auto(user.phoneNumber, user.uid)
        } else {
          console.log("User is not signed in");
        }
      });
    }

  },[])



  const userDBcheck_auto = async (uphone, uuid) => {
    //const uuid = Authentication.currentUser.uid;
    //console.log(`nav logic ${uuid}`)

    const dbRef = ref(ClientDatabase(client_id, client_uid));
    setRefreshCount(refreshCount + 1)
    get(child(dbRef, `/team/${uphone}/${uuid}`)).then((snapshot) => {
      if (snapshot.exists()) {
        //console.log(snapshot.val());
        //check role
        get(child(dbRef, `/team/${uphone}`)).then((snapshot) => {
          if (snapshot.val().role === "manager" || snapshot.val().role === "owner"){
            navigate(`/${client_id}/${client_uid}/manager/dashboard`)
          }else{
            console.log("employee")
            navigate(`/${client_id}/${client_uid}/dashboard`);
          }
        })
        
      } else {
        //console.log("Re check user db search");
      }
    }).catch((error) => {
      console.error(error);
    });
  }
  
}

export default UserDbCheck;