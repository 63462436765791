import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { useParams } from "react-router-dom";
import React, { useEffect, useState} from "react"



function AdminDatabase(){

  //const {client_id, client_uid} = useParams();

  
  const tmp_admin_firebaseConfig = {
    // ...
    // The value of `databaseURL` depends on the location of the database
    databaseURL: "https://oneround-default-rtdb.firebaseio.com/",
    apiKey: "AIzaSyBd_NPh_B9--YKn0eLwxdPXHNKRhSWu1pY",
    authDomain: "oneround.firebaseapp.com",
    projectId: "oneround",
    storageBucket: "oneround.appspot.com",
    messagingSenderId: "1024651262821",
    appId: "1:1024651262821:web:1ef5b782af69f7703e3f8c",
    measurementId: "G-94WXSSBWBK"
  };

  const admin_app = initializeApp(tmp_admin_firebaseConfig, 'client_app');

  const admin_database = getDatabase(admin_app);

  //console.log("This seems to work")

  return admin_database
  

}

export default AdminDatabase