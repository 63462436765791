import { useEffect, useState } from "react"
import { database } from '../firebase-config';
import { doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";

import { ref, child, get } from "firebase/database";



function ClientCheck(){

    const {client_id} = useParams();
    const navigate = useNavigate();
    const [refreshCount, setRefreshCount] = useState(0);


    useEffect(() => {
        if(refreshCount === 0 ){
            getClients();
        }else{
            console.log("refresh count is greater than 1 (DB Protection)")
          }
    },[])


    // const getClients = async (e) => {

    //     const userRef = doc(db, "clients", client_id);
    //     const docSnap = await getDoc(userRef);
    //     if (docSnap.exists()) {
    //         //client is present
    //     } else {
    //         // doc.data() will be undefined in this case
    //         console.log("No client document!");
    //         navigate("/client/404")
    //     }
    //     setRefreshCount(refreshCount + 1);
    // }

    const getClients = (e) => {

        const dbRef = ref(database);
        get(child(dbRef, `clients/${client_id}`)).then((snapshot) => {
        if (snapshot.exists()) {
            //console.log(snapshot.val());
            //client is present
        } else {
            // doc.data() will be undefined in this case
            console.log("No client document!");
            navigate("/client/404")
        }
        }).catch((error) => {
        console.error(error);
        });

    }

}

export default ClientCheck;