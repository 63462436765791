/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react"
import AdminNavBar from "../adminNavbar";
import FloatingActionButton from "../../components/fab";
import { useParams, useNavigate } from "react-router-dom";

import { useFirestoreDocument, useFirestoreQuery } from "@react-query-firebase/firestore";
import { QueryClient, QueryClientProvider } from 'react-query'
import { httpsCallable } from 'firebase/functions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/loading";


import { Authentication, database, Functions } from "../../firebase-config";
//import { collection, doc, getDoc, getDocs, setDoc, where, query,QuerySnapshot,DocumentData, } from "firebase/firestore";
import { getDatabase, ref, query, remove,set,get,child } from "firebase/database";
import ClientDatabase from "../../firebase-config-client";


window.managerName = ""

function AdminManageClient(){

    const navigate = useNavigate();
    const {client_id, client_uid} = useParams();
    const queryClient = new QueryClient()



    const [clientName, setClientName] = React.useState("");
    const [clientUID, setClientUID] = React.useState("");
    const [clientManager, setClientManager] = React.useState("");
    const [clientOwnerName, setClientOwnerName] = React.useState("");
    const [clientTeam, setClientTeam] = React.useState([]);
    const [response, setResponse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const errorAlert = (msg) => toast.error(msg,{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

    const getTeam = (e) => {

        const dbRef = ref(ClientDatabase(client_id, client_uid));
        get(child(dbRef, `/team`)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            let team = snapshot.val();
            setClientTeam(team);

        

            for(var x in team) {
                if(team[x].role == "owner"){
                    setClientOwnerName(team[x].name)
              }
            }


        } else {
            console.log("No data available");
        }
        }).catch((error) => {
        console.error(error);
        });

    }


    useEffect(() => {
        getTeam();
    },[])





    const RenderTeam = () => {

        if(Object.keys(clientTeam).length == 0){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Loading...</h3>
                        </div>
                    </div>
                </div>
            )
        }else{
                let displayed = []
                Object.keys(clientTeam).forEach((key) => {
                    //console.log(key, clientTeam[key].role);

                    displayed.push(
                        <div key={key} className="card shadow-1">
                            <li>
                                <p>{clientTeam[key].name}</p>
                                <p><span style={{fontSize:12}}>Role:</span> {clientTeam[key].role}</p>
                                <a href={`/admin/clients/`}><input style={{right:10, top:"20px", position:"absolute"}}type="submit" value="Select"/></a>
                            </li>
                        </div>)

                  });
    
                return(
                    <div>
                        {displayed}
                    </div>
                )
            }    
        }

    const deleteClient = () => {
        console.log("delete client")
        remove(ref(database, `clients/${client_id}`))
        .then(() => {
        // Data saved successfully!
        deleteDB()
        })
        .catch((error) => {
        // The write failed...
        });
    }

    const deleteDB = async (dbkey) => {

        setIsLoading(true)

        const disableDatabase = httpsCallable(Functions, 'disableDatabase');
  
        //console.log(`oneround-${clientUID}`)
  
        try {
          // Call the function with the data you want to include
          const result = await disableDatabase({dbID: `oneround-${client_id}-${client_uid}`});
          console.log("awaiting createdb result")
    
          // Set the response state with the response from the function
          setResponse(result);
          console.log(result)
          const jsonString = result.data;
          const jsonObject = JSON.parse(jsonString);
          console.log(jsonObject)
          if (jsonString.includes("error")){
            errorAlert(jsonObject.error.message)
            setIsLoading(false)
            if(jsonObject.error.message == "Namespace does not exist." || jsonObject.error.message == "The database is already deleted."){
                errorAlert("Deleted only from admin DB")
                navigate('/admin/clients')
            }
            return
          }else{
  
            const deleteDatabase = httpsCallable(Functions, 'deleteDatabase');
            try {
              // Call the function with the data you want to include
              const result = await deleteDatabase({dbID: `oneround-${client_id}-${client_uid}`});
              console.log("awaiting updaterules result")
              // Set the response state with the response from the function
              setResponse(result);
              console.log(result)
              setIsLoading(false)
              
            } catch (error) {
              // Handle error
              console.log(error);
            }
  
            setIsLoading(false)
            navigate('/admin/clients')
            //initClientDatabase(clientUID + "-" + dbkey)
            
            
          }
        } catch (error) {
          // Handle error
          console.log(error);
          setIsLoading(false)
        }
        //setIsLoading(false)
      }

      const handleNav = (key) => {
        navigate(`/admin/${client_id}/${client_uid}/menu/${key}`)
    }


  return (
    <>
     <AdminNavBar />
     <Loading isLoading={isLoading}/>
     <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
     <FloatingActionButton />
     <div className="container">
        <div className="register_container">
        <h2 style={{color: '#eb5228'}}>Manage Client</h2>
            <div className="card shadow-1" onClick={() => navigate(`/admin/clients/${client_id}/${client_uid}/team`)}>
                <li>                              
                    <div style={{height: "100%", lineHeight: "60px", alignItems:"center", textAlign:"center", }}>
                        <span style={{fontSize:30, color:"#eb5228", textAlign:"center", display:"inline-block", verticalAlign:"middle",lineHeight: "normal"}}>Manage {client_id} Team</span>
                    </div> 
                </li>
            </div>
            <div className="card shadow-1" onClick={() => navigate(`/admin/clients/${client_id}/${client_uid}/menu`)}>
                <li>                              
                    <div style={{height: "100%", lineHeight: "60px", alignItems:"center", textAlign:"center", }}>
                        <span style={{fontSize:30, color:"#eb5228", textAlign:"center", display:"inline-block", verticalAlign:"middle",lineHeight: "normal"}}>Manage {client_id} Menu Services</span>
                    </div> 
                </li>
            </div>
            <button className="btn" type="submit" onClick={() => deleteClient()} >Delete Client</button>
        </div>
    </div>
    
    </>
        
  )
}

export default AdminManageClient