/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import NavBar from "../../components/navbar";
import FloatingActionButton from "../../components/fab";
import { ToastContainer, toast } from 'react-toastify';
import { useParams, useNavigate } from "react-router-dom";

import ManagerCheck from "../managerCheck"





import { Authentication } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { database } from '../../firebase-config';
import {ref,get,child,onValue} from "firebase/database";
import ClientDatabase from "../../firebase-config-client";
import { getMessaging, getToken } from "firebase/messaging";





function ManagerDashboard(){

    //PRE-CHECKS
    ManagerCheck();

    const {client_id, user, client_uid} = useParams();
    const navigate = useNavigate();

    //STATES
    const [clientOrders, setClientOrders] = useState("")

    useEffect(() => {
        //notifTest();
        //doMessage();
      //getOrders();
  },[])

  const notifTest = () => {
    if (!("Notification" in window)) {
        console.log("Browser does not support desktop notification");
      } else {
        Notification.requestPermission();
        if (Notification.permission !== "granted") {
            Notification.requestPermission().then(function (permission) {
              if (permission === "granted") {
                console.log("Permission to send notifications has been granted");
              } else {
                console.log("Permission to send notifications has been denied");
              }
            });
          }
      }
  }

  const doMessage = () => {
    const messaging = getMessaging();
    getToken(messaging, {vapidKey: "BL2acfh92_C5xZuK5-5UIuqZGdt_15B-OvxHB4wY14Bw9FIHUmBx7G7inIC-qHTWFoHbiHskEldnoSt3oPSXBNQ"}).then((currentToken) => {
        if (currentToken) {
            console.log(currentToken);
            // Send the token to your server and update the UI if necessary
            // ...
        } else {
            // Show permission request UI
            console.log('No registration token available. Request permission to generate one.');
            // ...
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
    });
  }

    const getOrders = (e) => {

      const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders`);
      onValue(orderRef, (snapshot) => {
        const data = snapshot.val();
        //console.log("data: " + data);
        setClientOrders(data);
      });
  }



    const RenderOrders = () => {

      if(Object.keys(clientOrders).length == 0){
          return(
              <div className="client_list_container">
                  <div className="client_list">
                      <div className="client_list_item">
                          <h3 style={{fontSize:12, color:"white", padding: 20}}>Loading...</h3>
                      </div>
                  </div>
              </div>
          )
      }else{
              let displayed = []
              Object.keys(clientOrders).forEach((key) => {
                  //console.log(key, clientTeam[key].role);
                  displayed.push(
                      <div key={key} className="card shadow-1">
                          <li>
                              
                              <p style={{fontSize:15}}>Order#: <span style={{fontSize:12, color:"#eb5228"}}>{key}</span></p>
                             
                              <p><span style={{fontSize:12}}>Status: </span>{clientOrders[key].state}</p>
                              <a href={`/${client_id}/${client_uid}/manager/team/${user}`}><input style={{right:10, top:"20px", position:"absolute"}}type="submit" value="Modify"/></a>
                          </li>
                      </div>)
                  

                });
  
              return(
                  <div style={{paddingTop:5}}>
                      {displayed}
                  </div>
              )
          }    
      }

  return (
    <>
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
     <div>
        <p>Dashboard teehee</p>
     </div>
     <FloatingActionButton />
    </>
        
  )
}

export default ManagerDashboard