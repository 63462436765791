import React, {useState,useEffect, Button} from "react"
import { Authentication } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged, signInWithCredential } from "firebase/auth";
import { database } from '../firebase-config';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import logo from '../logo.png'

import { ref, child, get } from "firebase/database";
import ClientDatabase from "../firebase-config-client";


import ClientCheck from "./clientCheck";
import 'react-phone-number-input/style.css'
//import PhoneInput from 'react-phone-number-input'

import '../css/Auth.css';


function Auth(){

  ClientCheck()

    const country_code = "+1"

    const [phone, setPhone] = useState(country_code);
    const [expandForm, setExpandForm] = useState(false);
    const [expandCodeResend, setExpandCodeResend] = useState(false);
    const [refreshCount, setRefreshCount] = useState(0);
    const [recaptchaSolved, setRecaptchaSolved] = useState(false);
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate();
    const {client_id, client_uid} = useParams();
    const [userRole, setUserRole] = useState("")



  // const wepoop = () => {
  //   const dbRef = ref(ClientDatabase(client_id, client_uid));
  //               get(child(dbRef, `/`)).then((snapshot) => {
  //                 if (snapshot.exists()) {
  //                   console.log(snapshot.val().name);
  //                 } else {
  //                   console.log("How did you get here?");
  //                 }
  //               }).catch((error) => {
  //                 console.error(error);
  //               });
  // }

  // wepoop()


    const errorAlert = (msg) => toast.error(msg,{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  
    //const user = Authentication.currentUser;

    useEffect(() => {
      
      if(refreshCount <= 1){
        onAuthStateChanged(Authentication, (user) => {
          if (user) {
            //setAuthUser(user);
            // ...
            console.log("User is signed in");
            userDBcheck_auto(user.phoneNumber, user.uid)
          } else {
            console.log("init: User is not signed in");
          }
        });
  
      }else{
        console.log("refresh count is greater than 1 (DB Protection)")
      }

    },[])



  
    

    const generateCaptcha = () => {
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => {
              // reCAPTCHA solved, allow signInWithPhoneNumber.
              setRecaptchaSolved(true)
            }
          }, Authentication);
    }

    const requestOTP = (e) => {
      e.preventDefault()

      console.log("here")
      if (phone.length === 12){

        console.log(phone)
        
        const dbRef = ref(ClientDatabase(client_id, client_uid));
        get(child(dbRef, `/`)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            //client is present in admin


          let result = snapshot.val().team[phone];

          console.log(result)

          if(typeof result !== "undefined"){
            //var valueOfField = map['field'];
            
            setUserRole(result.role)
            //console.log(phone)
            setExpandCodeResend(false)
            setExpandForm(true)
            if(recaptchaSolved === false){
              generateCaptcha()
            }
            let appVerifier =  window.recaptchaVerifier;

                setPersistence(Authentication, browserSessionPersistence)
                .then( async () => {
                  // Existing and future Auth states are now persisted in the current
                  // session only. Closing the window would clear any existing state even
                  // if a user forgets to sign out.
                  // ...
                  // New sign-in will be persisted with session persistence.
                  return signInWithPhoneNumber(Authentication,phone,appVerifier)
                  .then(confirmationResult => {
                     window.confirmationResult = confirmationResult;
                     console.log(confirmationResult)
                })
                .catch((error) => {
                  // Handle Errors here.
                  //const errorCode = error.code;
                  const errorMessage = error.message;
                  errorAlert(errorMessage)
                });
            }).catch((error) => {
                console.log(error)
            });
            setTimeout(() => {
              setExpandCodeResend(true);
            }, 8000);

        } else {
          // doc.data() will be undefined in this case
          console.log("User does not belong client");
          errorAlert(`User is not a part of the ${client_id.toUpperCase()} team.`)
          //return false;
      }

        } else {
            // doc.data() will be undefined in this case
            console.log("No client document!");
            navigate("/client/404")
        }
        }).catch((error) => {
        console.error(error);
        });
      }else{
          errorAlert("Please enter a valid phone number \n e.g. +1654447890")
        } 
    }

    const verifyOTP = (e) => {
        e.preventDefault()
        let code = e.target.value;
        setOTP(code);
        if (code.length === 6){
             window.confirmationResult.confirm(code).then((result) => {
                console.log(result)
                const user = result.user;
                console.log(user)
                navLogic()
            }).catch((error) => {
                console.log(error)
                errorAlert(error.message)
                
            }); 

        }
    }

    const navLogic = () => {
      //console.log("in nav logic")
      const uuid = Authentication.currentUser.uid;
      //console.log(`nav logic ${uuid}`)

      const dbRef = ref(ClientDatabase(client_id, client_uid));
      get(child(dbRef, `/team/${phone}`)).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          //check if registered to auth
          if (typeof snapshot.val().uid === "undefined" || snapshot.val().uid == "" ){
            //not registered
            console.log("not registered")
            navigate(`/${client_id}/${client_uid}/register`);
          }else{
              //check role
            if (snapshot.val().role == "manager" || snapshot.val().role == "owner"){
              navigate(`/${client_id}/${client_uid}/manager/dashboard`)
            }else{
              console.log("employee")
              navigate(`/${client_id}/${client_uid}/dashboard`);
            }
          }
          
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    const userDBcheck_auto = (uphone, uuid) => {
      //checking if user is already logged in
      //const uuid = Authentication.currentUser.uid;
      console.log(`db user check ${uuid}`)
      console.log(uphone)

      const dbRef = ref(ClientDatabase(client_id, client_uid));
      setRefreshCount(refreshCount + 1)
      get(child(dbRef, `/team/${uphone}/uid`)).then((snapshot) => {
        if (snapshot.exists() && snapshot.val() == uuid) {
          //console.log(snapshot.val());
          //check role
          get(child(dbRef, `/team/${uphone}`)).then((snapshot) => {
            if (snapshot.val().role == "manager" || snapshot.val().role == "owner"){
              console.log("manager")
              navigate(`/${client_id}/${client_uid}/manager/dashboard`)
            }else{
              console.log("employee")
              navigate(`/${client_id}/${client_uid}/dashboard`);
            }
          })
          
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }
    


  return (
    <>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
    <div className="container">
        <div className="box">
          <form className="form" onSubmit={requestOTP}>
          <img src={logo} alt="Logo" />
              <h2>SIGN IN</h2>
              <div className="inputBox">
                  <input type="tel" value={phone} required 
                  onChange={(e) => setPhone(e.target.value)}
                  maxLength={12}
                  onKeyDown={(event) => {
                    if(event.key !== "Backspace" && event.key !== "Delete"){
                        if (!/[0-9\+]/.test(event.key)) {
                            console.log(event.key)
                            event.preventDefault();
                        }
                    }
                  }}
                  />
                  <span>Phone Number</span>
                  <i></i>
              </div>
              {expandForm === true ? 
                <>
                    <div className="inputBox">
                        <input type="tel" required value={OTP} maxLength={6} onChange={verifyOTP} />
                        <span>code</span>
                        <i></i>
                    </div>
                    {expandCodeResend === true ? 
                      <>
                          <div className="links">
                            <p onClick={requestOTP}>Resend code</p>
                          </div>
                      </>
                      :
                      null
                    }
                    
                </>
                :
                null
              }
              {expandForm === false ? 
                <>
                    <input type="submit" value="Verify" />
                </>
                :
                null
              }
              
          </form>
          <div id="recaptcha-container"></div>
      </div>
      </div>
      </>
  )
}



export default Auth