/* eslint-disable no-unused-vars */
import React, {useState} from "react"
import AdminNavBar from "../adminNavbar";
import FloatingActionButton from "../../components/fab";
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from "../../components/loading";


import { httpsCallable } from 'firebase/functions';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";


import { Authentication, database, Functions } from "../../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { getDatabase, ref, child, get, set, push } from "firebase/database";

function AdminClientOnboarding(){

    const navigate = useNavigate();

    const [clientName, setClientName] = React.useState("");
    const [clientUID, setClientUID] = React.useState("");
    const [clientManager, setClientManager] = React.useState("+1");
    const [response, setResponse] = useState(null);
    const [existingClient, setExistingClient] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [db_uid, setDB_UID] = useState("");


    const errorAlert = (msg) => toast.error(msg,{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });

    const createDB = async (dbkey) => {

      const createDatabase = httpsCallable(Functions, 'createDatabase');

      //console.log(`oneround-${clientUID}`)

      try {
        // Call the function with the data you want to include
        const result = await createDatabase({dbID: `oneround-${clientUID}-${dbkey}`});
        console.log("awaiting createdb result")
  
        // Set the response state with the response from the function
        setResponse(result);
        console.log(result)
        const jsonString = result.data;
        const jsonObject = JSON.parse(jsonString);
        console.log(jsonObject)
        if (jsonString.includes("error")){
          errorAlert(jsonObject.error.message)
          return
        }else{

          const updateRules = httpsCallable(Functions, 'updateRules');
          try {
            // Call the function with the data you want to include
            const result = await updateRules({dbID: `oneround-${clientUID}-${dbkey}`});
            console.log("awaiting updaterules result")
            // Set the response state with the response from the function
            setResponse(result);
            console.log(result)
            
          } catch (error) {
            // Handle error
            console.log(error);
          }

          setIsLoading(false)
          initClientDatabase(clientUID + "-" + dbkey)
          
          
        }
      } catch (error) {
        // Handle error
        console.log(error);
      }
    }


    const handleSubmit = async (e) =>{
      let clientCreated = false
      const dbRef = ref(database);
      get(child(dbRef, `clients/${clientUID}`)).then((snapshot) => {
        if (snapshot.exists()) {
          errorAlert("Client ID already exists")
          //return
          setExistingClient(true)
        } else {
          //proceed
          setIsLoading(true)
          const uid_ref_key = push(child(ref(database), 'clients')).key.toLowerCase().replace(/[^a-z0-9]/gi, '');

          setDB_UID(uid_ref_key)
          console.log(uid_ref_key)

          //implement databse uid here 
          set(ref(database, 'clients/' + clientUID), {
            name: clientName.trim(),
            refURL: `https://oneround-${clientUID}-${uid_ref_key}.firebaseio.com/`,
            uid: uid_ref_key,
        })
        .then(() => {
        // Data saved successfully!
        createDB(uid_ref_key)
        })
        .catch((error) => {
        // The write failed...
        });
        }
      }).catch((error) => {
        console.error(error);
      });
        //errorAlert("Client DB failed to create")
      }

    const autoFill = (value) =>{
      let firstPass = value.toLowerCase().replace(/\s/g, '-')
      setClientUID(firstPass.toLowerCase().replace(/[^a-z\-0-9]/gi, ''))
      //setClientUID()
      setClientName(value)
    }

    

    const initClientDatabase = (dbID) =>{

      const tmp_firebaseConfig = {
        // ...
        // The value of `databaseURL` depends on the location of the database
        databaseURL: `https://oneround-${dbID}.firebaseio.com/`,
        apiKey: "AIzaSyBd_NPh_B9--YKn0eLwxdPXHNKRhSWu1pY",
        authDomain: "oneround.firebaseapp.com",
        projectId: "oneround",
        storageBucket: "oneround.appspot.com",
        messagingSenderId: "1024651262821",
        appId: "1:1024651262821:web:1ef5b782af69f7703e3f8c",
        measurementId: "G-94WXSSBWBK"
      };

      // Initialize Firebase
      const tmp_app = initializeApp(tmp_firebaseConfig, 'tmp_app');


        // Initialize Realtime Database and get a reference to the service
        const tmp_database = getDatabase(tmp_app);
        //const dbRef = ref(tmp_database);
        set(ref(tmp_database, '/'), {
            name: clientName.trim(),
            team:{ [clientManager] :{role:"owner", uid:""}},
            roles: {manager:"manager"},
            orders: "",
            totalOrders: 1,
        })
        .then(() => {
        // Data saved successfully!
        navigate(`/admin/clients`);
        })
        .catch((error) => {
        // The write failed...
        });

    }


  return (
    <>
     <AdminNavBar />
     <Loading isLoading={isLoading}/>
     <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
     <div className="container">
        <div className="register_container " >
            <h2 style={{color: '#eb5228'}}>Onboarding</h2>
            <div className="inputBox">
                <input type="text" required onChange={(e) => autoFill(e.target.value)}/>
                <span>Client Name</span>
                <i></i>
            </div>
            <div className="inputBox">
                <input type="text" value={clientUID} required onChange={(e) => setClientUID(e.target.value)}/>
                <span>Client Unique ID</span>
                <i></i>
            </div>
            <div className="inputBox">
                <input type="text" required value={clientManager} onChange={(e) => setClientManager(e.target.value)}/>
                <span>Manager's Phone Number</span>
                <i></i>
            </div>
            
            
            <div className="links">
            </div>
            <button className="btn" type="submit" onClick={handleSubmit}>Submit</button>
        </div>
    </div>
     <FloatingActionButton />
    </>
        
  )
}

export default AdminClientOnboarding