/* eslint-disable no-unused-vars */
import React from "react"
import AdminNavBar from "../adminNavbar";
import MenuServices from "../../pages/menuServices";

function AdminManageMenuServices(){

  return (
    <>
     <AdminNavBar />
     <MenuServices />
    </>
        
  )
}

export default AdminManageMenuServices