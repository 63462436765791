import React, {useState,useEffect} from "react"
import { Authentication } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, signInWithEmailAndPassword, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { database } from '../firebase-config';
import { useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import logo from "../logo.png";
import 'react-toastify/dist/ReactToastify.css';

import { getDatabase, ref, child, get } from "firebase/database";


import '../css/Auth.css';


function AdminAuth(){


    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [refreshCount, setRefreshCount] = useState(0);
    const [recaptchaSolved, setRecaptchaSolved] = useState(false);
    const [OTP, setOTP] = useState("");
    const navigate = useNavigate();
    const {client_id} = useParams();


    const errorAlert = (msg) => toast.error(msg,{
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
      });
  
    //const user = Authentication.currentUser;

    useEffect(() => {
      
      if(refreshCount <= 1){
        onAuthStateChanged(Authentication, (user) => {
          if (user) {
            //setAuthUser(user);
            // ...
            console.log("User is signed in");
            adminDBcheck_auto(user.uid)
          } else {
            console.log("User is not signed in");
          }
        });
  
      }else{
        console.log("refresh count is greater than 1 (DB Protection)")
      }

    },[])


    const logmein = (e) => {
        e.preventDefault()
        if(email === "" || pass === ""){
            errorAlert("Please enter a valid email and password")
        }else{
            setPersistence(Authentication, browserSessionPersistence)
            .then( async () => {
                signInWithEmailAndPassword(Authentication,email, pass)
                .then((userCredential) => {
                // Signed in 
                var user = userCredential.user;
                console.log(user.uid)
                adminNavLogic(user.uid)
                // ...
                })
                .catch((error) => {
                errorAlert(error.message)
                });

                
            }).catch((error) => {
                console.log(error)
            });
        }
    }


    const adminNavLogic = async (uuid) => { 
      const dbRef = ref(database);
      get(child(dbRef, `admins/${uuid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          navigate(`/admin/dashboard`);
        } else {
          console.log("Not an admin");
          errorAlert("You are not an Admin")
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    

    const adminDBcheck_auto = async (uuid) => {
      const dbRef = ref(database);
      setRefreshCount(refreshCount + 1)
      get(child(dbRef, `admins/${uuid}`)).then((snapshot) => {
        if (snapshot.exists()) {
          console.log(snapshot.val());
          navigate(`/admin/dashboard`);
        } else {
          console.log("No data available");
        }
      }).catch((error) => {
        console.error(error);
      });
    }


  return (
    <>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
      />
    <div className="container">
        <div className="box">
          <form className="form" onSubmit={logmein}>
          <img src={logo} alt="Logo" />
              <h2>SIGN IN</h2>
              <div className="inputBox">
                  <input type="email" value={email} required onChange={(e) => setEmail(e.target.value)}/>
                  <span>Email</span>
                  <i></i>
              </div>
              <div className="inputBox">
                  <input type="password" value={pass} required onChange={(e) => setPass(e.target.value)}/>
                  <span>Password</span>
                  <i></i>
              </div>
              
                <input style={{}}type="submit" value="Sign In" />
          </form>
          <div id="recaptcha-container"></div>
      </div>
      </div>
      </>
  )
}



export default AdminAuth