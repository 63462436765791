/* eslint-disable no-unused-vars */
import React,{useEffect, useState}from "react"
import { db } from '../firebase-config';
import { doc, getDoc } from "firebase/firestore";
import { useParams, useNavigate } from "react-router-dom";
import Loading from "../components/loading";
import 'react-toastify/dist/ReactToastify.css';
import LogOut from "../auth/logout";
import { ToastContainer, toast } from 'react-toastify';

import '../css/navbar.css';

import AdminCheck from "./adminCheck"; 
import LoginCheck from "../auth/loginCheck";


function AdminNavBar(){

    AdminCheck();

    const [isLoading, setIsLoading] = useState(false);
    const [clientName_DB, setClientName_DB] = useState("");
    const [clientID_DB, setClientID_DB] = useState("");

    const navigate = useNavigate();

    useEffect(() => {

        
    },[])


  
  return (
    <>
        <Loading isLoading={isLoading}/>
            <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        <header className="header">
            <a href="" className="logo">Admin</a>
            <input className="menu-btn" type="checkbox" id="menu-btn" />
            <label className="menu-icon" htmlFor="menu-btn"><span className="navicon"></span></label>
            <ul className="menu">
                <li><a href={`/admin/dashboard`}>Dashboard</a></li>
                <li><a href={`/admin/clients`}>Clients</a></li>
                <li><a href={`/admin/onboarding`}>Onboarding</a></li>
                <li><a onClick={LogOut} >Sign Out</a></li>
            </ul>
        </header>
        <div className="pagePadding">

        </div>
    </>
  )
}

export default AdminNavBar