/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from "react"
import NavBar from "../../components/navbar";
import FloatingActionButton from "../../components/fab";
import { useParams, useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import { useFirestoreDocument, useFirestoreQuery } from "@react-query-firebase/firestore";
import { QueryClient, QueryClientProvider } from 'react-query'
import "../../css/Auth.css"
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAdd, MdCreate, MdCodeOff ,MdAddTask } from "react-icons/md";
/* eslint-disable no-unused-vars */


import { Authentication, database } from "../../firebase-config";
//import { collection, doc, getDoc, getDocs, setDoc, where, query,QuerySnapshot,DocumentData, } from "firebase/firestore";
import { getDatabase, ref, query, orderByChild,set,get,child,remove } from "firebase/database";
import ClientDatabase from "../../firebase-config-client";


window.managerName = ""

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(21, 23, 25, 0.9)'
      },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60%',
        backgroundColor: '#27282c',
        borderRadius: 10,
        border: 'none',
        paddingTop: 60
    },
  };

  Modal.setAppElement('#root');

function ManagerManageTeam(){

    const navigate = useNavigate();
    const {client_id, user, client_uid} = useParams();
    const queryClient = new QueryClient()


    const [clientName, setClientName] = React.useState("");
    const [clientUID, setClientUID] = React.useState("");
    const [clientManager, setClientManager] = React.useState("");
    const [clientOwnerName, setClientOwnerName] = React.useState("");
    const [clientTeam, setClientTeam] = React.useState([]);
    const [clientRoles, setClientRoles] = React.useState([]);
    const [roleModalIsOpen, setRoleIsOpen] = React.useState(false);
    const [memberModalIsOpen, setMemberIsOpen] = React.useState(false);
    const [addStateRole, setStateAddRole] = React.useState("");
    const [roleOptions, setRoleOptions] = React.useState("none");
    const [phone, setPhone] = React.useState("+1");



    const getClient = (e) => {

        const dbRef = ref(ClientDatabase(client_id, client_uid));
        get(child(dbRef, `/team`)).then((snapshot) => {
        if (snapshot.exists()) {
            console.log(snapshot.val());
            let team = snapshot.val();
            setClientTeam(team);
            

            for(var x in team) {
                if(team[x].role == "owner"){
                    setClientOwnerName(team[x].name)
              }
            }

        } else {
            console.log("No data available");
        }
        }).catch((error) => {
        console.error(error);
        });

        get(child(dbRef, `/roles`)).then((snapshot) => {
            if (snapshot.exists()) {
                console.log(snapshot.val());
                let team = snapshot.val().team;
                setClientRoles(snapshot.val());
    
            } else {
                console.log("No data available");
            }
            }).catch((error) => {
            console.error(error);
            });

    }


    useEffect(() => {
        getClient();
    },[])



    const RenderTeam = () => {

        if(Object.keys(clientTeam).length == 0){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Loading...</h3>
                        </div>
                    </div>
                </div>
            )
        }else{
                let displayed = []
                Object.keys(clientTeam).forEach((key) => {
                    //console.log(key, clientTeam[key].role);

                    if (clientTeam[key].role != "owner"){
                    displayed.push(
                        <div key={key} className="card shadow-1">
                            <li>
                                {typeof clientTeam[key].name == "undefined"
                                ? <p style={{fontSize:15}}>{key}<span style={{fontSize:12, color:"#eb5228"}}> unregistered</span></p>
                                : <p>{clientTeam[key].name}</p>
                                }
                                <p><span style={{fontSize:12}}>Role:</span> {clientTeam[key].role}</p>
                                <a href={`/${client_id}/${client_uid}/manager/team/${user}`}><input style={{right:10, top:"20px", position:"absolute"}}type="submit" value="Select"/></a>
                            </li>
                        </div>)
                    }

                  });
    
                return(
                    <div>
                        {displayed}
                    </div>
                )
            }    
        }

        const RenderRoles = () => {

            if(Object.keys(clientTeam).length == 0){
                return(
                    <div className="client_list_container">
                        <div className="client_list">
                            <div className="client_list_item">
                                <h3 style={{fontSize:12, color:"white", padding: 20}}>Loading...</h3>
                            </div>
                        </div>
                    </div>
                )
            }else{
                    let displayed = []
                    Object.keys(clientRoles).forEach((key) => {
                        //console.log(key, clientTeam[key].role);
    
                        displayed.push(
                            <div key={key} className="card shadow-1" style={{height:40, padding:5, justifyContent:"center"}}>
                                <li>
                                    <p style={{paddingLeft:20, margin:0, fontSize:15, top:10, position:"absolute"}}>{key}</p>
                                    <a onClick={(e) => deleteRole(key)}><input style={{right:15, top:"0px", position:"absolute", justifyContent:"center", height: 25, width: 80, padding: 0, marginTop:7 }}type="submit" value="Delete"/></a>
                                </li>
                            </div>)
                      });
        
                    return(
                        <div>
                            {displayed}
                        </div>
                    )
                }    
        }
    
    const deleteRole = (role) => {
        let _delete = true;

        Object.keys(clientTeam).every((key) => {
            //console.log(key, clientTeam[key].role);

            if (clientTeam[key].role == role){
                alert("Cannot delete " + role + " as it is assigned to a team member")
                _delete = false;
                return false;
            }
            return true;
            });
            if(_delete){
                    const dbRef = ref(ClientDatabase(client_id, client_uid));
                    remove(child(dbRef, `/roles/${role}`)).then(() => {
                    console.log("Remove succeeded.")
                    window.location.reload(false);
                    }).catch((error) => {
                    console.log("Remove failed: " + error.message)
                });
            }
    }


    function openRoleModal() {setMemberIsOpen(false);setRoleIsOpen(true);}
    function afterOpenRoleModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }
    function closeRoleModal() {setRoleIsOpen(false);}
    function openMemberModal() {setRoleIsOpen(false);setMemberIsOpen(true);}
    function afterOpenMemberModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }
    function closeMemberModal() {setMemberIsOpen(false);}

    const addMember = (e) =>{

            e.preventDefault()

            if(roleOptions != "none"){
                set(ref(ClientDatabase(client_id, client_uid), `/team/${phone}`), 
                {
                    role: roleOptions,
                    uid: "",
                    name: "",
                })
                    .then(() => {
                    // Data saved successfully!
                    closeRoleModal();
                    window.location.reload(false);
                    })
                    .catch((error) => {
                    // The write failed...
                    });

            }else{
                alert("Please select a role")
            }
            
      
                  
      
              
    } 

    const addRole = (e) =>{
        e.preventDefault()

        let role = addStateRole.toLowerCase();

            set(ref(ClientDatabase(client_id, client_uid), `/roles/${role.trim()}`), {menu:""})
            .then(() => {
            // Data saved successfully!
            closeRoleModal();
            window.location.reload(false);
            })
            .catch((error) => {
            // The write failed...
            });
    }


    const RenderAddRole = () => {

        let displayed = []
        Object.keys(clientRoles).forEach((role) => {
            //console.log(key, clientTeam[key].role);

            displayed.push(
                <option key={role} value={role}>{role}</option>)
            });

        return(
            <div>
                <select value={roleOptions} onChange={(e) => setRoleOptions(e.target.value)} style={{width: "100%", height: 50, borderRadius: 5, backgroundColor:"#eb5228", color: "white", borderColor: "#eb5228"}}>
                    <option value="none" selected disabled hidden>Select an Option</option>
                    {displayed}
                </select>
                
            </div>
        )

    }


  return (
    <>
     <Modal
        isOpen={roleModalIsOpen}
        onAfterOpen={afterOpenRoleModal}
        onRequestClose={closeRoleModal}
        style={customStyles}
        //contentLabel="Example Modal"
      >
        <button className="btn" type="submit" onClick={closeRoleModal} style={{width:50, height: 20, padding:0, right:10, top:0, position: "absolute"}}>close</button>
        <h2 style={{color: '#eb5228'}}>Onboarding</h2>
            <div className="inputBox">
            
                <input type="select" required onChange={(e) => setStateAddRole(e.target.value)}/>
                
                <span>Role Name</span>
                <i></i>
            </div>
            
            <div className="links">
            </div>
            <button className="btn" type="submit" onClick={addRole}>Submit</button>
      </Modal>


      <Modal
        isOpen={memberModalIsOpen}
        onAfterOpen={afterOpenMemberModal}
        onRequestClose={closeMemberModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="btn" type="submit" onClick={closeMemberModal} style={{width:50, height: 20, padding:0, right:10, top:0, position: "absolute"}}>close</button>
        <h2 style={{color: '#eb5228'}}>Add Member</h2>
            <div className="inputBox">
                <input type="text" required value={phone} onChange={(e) => setPhone(e.target.value)}/>
                <span>New Member's Phone Number</span>
                <i></i>
            </div>
            <div className="inputBox">
                <p style={{color: '#eb5228'}}>Role</p>
                <RenderAddRole />
            </div>
            
            
            <div className="links">
            </div>
            <button className="btn" type="submit" onClick={addMember} >Submit</button>
      </Modal>


     <div className="container">
        <div className="register_container" >
            <table style={{width:'100%', border: '0px solid'}}>
                <tbody>
                    <tr>
                        <td>
                            <p style={{color: 'white', fontSize: 13}} >Owner: {clientOwnerName}</p>

                        </td>
                        <td rowSpan={2}>
                            <h2 style={{color: '#eb5228', float:'right'}}>{client_id}</h2>
                        </td>
                    </tr>
                    <tr>
                        <td >
                            <p style={{color: 'white', fontSize: 13}}>placeholder</p>
                        </td>
                    </tr>
                    <tr style={{paddingTop:50}}>
                    
                        <td colSpan={2}>
                        <div style={{backgroundColor: "#1f1f1f", marginTop:40, paddingBottom:10, borderRadius:5}}>
                                <p style={{padding: 10, color:"white"}}>Roles</p>
                                    <RenderRoles/>
                            </div>
                            <div style={{backgroundColor: "#1f1f1f", marginTop:40, paddingBottom:10, borderRadius:5}}>
                                <p style={{padding: 10, color:"white"}}>Team</p>
                                    <RenderTeam />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            
            
            
            
            <div className="links">
            </div>
            {/* <button className="btn" type="submit" onClick={handleSubmit} >Submit</button> */}
        </div>
    </div>
    <Fab
      //mainButtonStyles={mainButtonStyles}
      icon={<MdAdd />}
      event={'click'}
      alwaysShowTitle={true}
      mainButtonStyles={{ backgroundColor: "#eb5228", bottom: 0, right: 0 }}
      //onClick={someFunctionForTheMainButton}
      >
        <Action
          //text= "title"
          style={{ backgroundColor: "#ffffff", width: "100%", borderRadius: 3}}
          children={<p style={{ color: "black", padding: 10}}>Add New Member</p>}
          onClick={openMemberModal}
        />
        <Action
          //text= "title"
          style={{ backgroundColor: "#ffffff", width: "100%", borderRadius: 3}}
          children={<p style={{ color: "black", padding: 10}}>Add New Role</p>}
          onClick={openRoleModal}
        />
      </Fab>
    </>
        
  )
}

export default ManagerManageTeam