import React, { useEffect, useState} from "react"
import NavBar from "../components/navbar";
import FloatingActionButton from "../components/fab";
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../components/loading";

/* eslint-disable no-unused-vars */




import { Authentication } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { database } from '../firebase-config';
import { getDatabase, ref, child, get, set } from "firebase/database";
import { useParams, useNavigate } from "react-router-dom";
import ClientDatabase from "../firebase-config-client";



function Dashboard(){

    const {client_id, client_uid} = useParams();
    const navigate = useNavigate();

    const [refreshCount, setRefreshCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {

    if(refreshCount <= 1){
      onAuthStateChanged(Authentication, (user) => {
        if (user) {
            userManager_auto(user.uid)
        } else {
          console.log("User is not signed in");
        }
      });
    }

  },[])

  const userManager_auto = (uuid) => {
    //setIsLoading(true)
    const dbRef = ref(ClientDatabase(client_id, client_uid));
    setRefreshCount(refreshCount + 1);
    get(child(dbRef, `clients/${client_id}/team`)).then((snapshot) => {
    if (snapshot.exists()) {
        console.log(snapshot.val());
        let team = snapshot.val();

        for(var x in team) {
          if((team[x].uid == uuid && team[x].role === "manager") || (team[x].uid == uuid && team[x].role === "owner")) {
              navigate(`/${client_id}/manager/dashboard`);
          }
        }
        
    } else {
        console.log("No data available");
    }
    }).catch((error) => {
    console.error(error);
    });
    //setIsLoading(false)
  }

  return (
    <>
    <Loading isLoading={isLoading}/>
     <NavBar />
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
     <div>
        <p>Dashboard</p>
     </div>
     <FloatingActionButton />
    </>
        
  )
}

export default Dashboard