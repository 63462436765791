/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react"
import { ToastContainer } from 'react-toastify';
import { useParams, useNavigate } from "react-router-dom";
import "../css/Auth.css"
import Modal from 'react-modal';
import { Fab } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAdd} from "react-icons/md";
import { onAuthStateChanged} from "firebase/auth";
import { Authentication  } from '../firebase-config';
import {ref,get,child,onValue, update,orderByChild, query, startAt} from "firebase/database";
import ClientDatabase from "../firebase-config-client";


const customStyles = {
    overlay: {
        backgroundColor: 'rgba(21, 23, 25, 0.9)'
      },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60%',
        backgroundColor: '#27282c',
        borderRadius: 10,
        border: 'none',
        paddingTop: 60
    },
  };


function Orders(){

    //PRE-CHECKS

    const {client_id, user, client_uid} = useParams();
    const navigate = useNavigate();

    //STATES
    const [clientOrders, setClientOrders] = useState("")
    const [userRole, setUserRole] = useState("")
    const [uuid, setUUID] = useState("")
    const [filters, setFilters] = useState(["requested","forMe"])
    const [filerModalIsOpen, setFilterModalIsOpen] = useState(false);
    const [filterRequestedChecked, setFilterRequestedChecked] = useState(true);
    const [filterForMeChecked, setFilterForMeChecked] = useState(true);
    const [filterPendingChecked, setFilterPendingChecked] = useState(false);
    const [filterAllChecked, setFilterAllChecked] = useState(false);


    useEffect(() => {
      getOrders();
  },[])

  Modal.setAppElement('#root');

    const getOrders = (e) => {

      onAuthStateChanged(Authentication, (user) => { 
        setUUID(user.uid)
        getRole(user);

        // get the current timestamp
        const now = Date.now();

      const orderRef = query(ref(ClientDatabase(client_id, client_uid), `/orders`), orderByChild('timestamp'), startAt(now-86400000));
      onValue(orderRef, (snapshot) => {
        let data = []
        snapshot.forEach((child) => {
          //console.log(child.key);
          data[child.key] = (child.val())
        });
        
        setClientOrders(data.sort());
        console.log(data)
        // const data = snapshot.val();
        // //console.log("data: " + data);
        // setClientOrders(data);
        // //applyFilters(data)
      })

      // const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders`);
      // onValue(orderRef, (snapshot) => {
      //   setClientOrders(snapshot.val());
      // })
    })

  }

  const getRole = (user) => {
    //const orderRef = ref(ClientDatabase(client_id, client_uid), `/team`);

    const orderRef = ref(ClientDatabase(client_id, client_uid));
    get(child(orderRef, `/team`)).then((snapshot) => {
      if (snapshot.exists()) {
        //console.log(snapshot.val());
        const data = snapshot.val();
      for (var key in data) {
        if (data[key].uid===user.uid) {
          setUserRole(data[key].role);
      }
      }

      } else {
        console.log("No data available");
      }
    }).catch((error) => {
      console.error(error);
    });
}

//Ack - mark as in progress
const ackOrder = (e) => {
  const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders/${e}`);
  update(orderRef, {
    status: "in progress"
  });
  console.log("ackOrder: " + e)
}

//ready - mark as ready
const readyOrder = (e) => {
  const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders/${e}`);
  update(orderRef, {
    status: "ready"
  });
}

//done - mark as complete
const doneOrder = (e) => {
  const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders/${e}`);
  update(orderRef, {
    status: "complete"
  });
}

//cancel - mark as cancelled
const cancelOrder = (e) => {
  const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders/${e}`);
  update(orderRef, {
    status: "cancelled"
  });
}

//decline - mark as declined
const declineOrder = (e) => {
  const orderRef = ref(ClientDatabase(client_id, client_uid), `/orders/${e}`);
  update(orderRef, {
    status: "declined"
  });
}

const isToday = (epochTime) => {
  // Convert the epoch time to a Date object
  const date = new Date(epochTime);
  //console.log(epochTime)
  // Get the current date
  const today = new Date();
  console.log(today.getDate() - 1)
  // Check if the date of the Date object is the same as today's date
  return date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() && 
    (date.getDate() === today.getDate());
}

// const applyFilters = (data) => {

//   onAuthStateChanged(Authentication, (user) => { 
//     setUUID(user.uid)

//     console.log(data)
//       let orderHolder = {}
//       if(Object.keys(data).length===0){
//         console.log("Loading...")
//     }else{
//       Object.keys(data).forEach((key) => {
//         if(filters.includes("requested") && data[key].status==="requested" && data[key].requestedBy===user.uid && isToday(data[key].timestamp)){
//           orderHolder[`${key}`] = data[key]
//           setClientOrders(orderHolder)
//         }if(filters.includes("forMe") && data[key].status==="requested" && isToday(data[key].timestamp)){
//           orderHolder[`${key}`] = data[key]
//           setClientOrders(orderHolder)
//         }
//       });
//     }
//   })
// }

function applyFilters(clientOrders, key) {
  let toDisplay = []
  var date = new Date(clientOrders.timestamp); 

  //console.log("here")

  toDisplay.push(
    <div key={key} className="card shadow-1" style={{paddingBottom:50}}>
        <li>
            
            <p style={{fontSize:15, alignItems:"center", textAlign:"center"}}>Order#: <span style={{fontSize:15, color:"#eb5228"}}>{clientOrders.readableOrderId}</span></p>

            <p style={{right:20, top:65, position:"absolute"}} ><span style={{fontSize:12}}>Status: </span>{capitalize(clientOrders.status)}</p>
            <p style={{right:20, top:42, position:"absolute"}} ><span style={{fontSize:12}}>Service: </span>{capitalize(clientOrders.service)}</p>
            <p style={{}} ><span style={{fontSize:12}}>Item: </span>{capitalize(clientOrders.lineItem)}</p>
            <p style={{}} ><span style={{fontSize:17}}>$</span>{clientOrders.price}</p>
            {(clientOrders.notes!=="") ?
            <p style={{}} ><span style={{fontSize:12}}>Notes: </span>{clientOrders.notes}</p>
            :
            null
            }
            <p style={{}} ><span style={{fontSize:12}}>Created: </span>{date.toLocaleString('en-GB', { hour:'numeric', minute:'numeric', hour12:true } )}</p>

            <div style={{transform: "translateX(-50%)", left:"50%",bottom:20,color:"white", height:30, width:70, padding:0, display:"flex",position:"absolute", justifyContent: "center"}}>
            
            {(clientOrders.status==="requested" && (clientOrders.service===userRole || userRole==="owner" || userRole==="manager")) ?
            <>
            
            <input style={{ color:"white", height:30, minWidth:100, backgroundColor:"green", marginLeft:3, marginRight:3,padding:0}}type="submit" onClick={() => ackOrder(key)} value="Acknowledge"/>
            

                <input style={{  color:"white",height:30, minWidth:70, padding:0, marginLeft:3, marginRight:3,}} onClick={() => declineOrder(key)} type="submit" value="Decline"/>
            
            </>
            
          :null
          }

          {(clientOrders.status==="in progress" && (clientOrders.service===userRole || userRole==="owner" || userRole==="manager")) ?
            <>
              <input style={{  color:"white",marginLeft:3, marginRight:3, height:30, minWidth:60, padding:0, backgroundColor:"green" }}type="submit" onClick={() => readyOrder(key)}value="Ready"/>
           
            </>
            
          :null
          }

          {(clientOrders.status==="ready" && (clientOrders.requestedBy===uuid || userRole==="owner" || userRole==="manager")) ?
            <>
              <input style={{ color:"white",marginLeft:3, marginRight:3, height:30, minWidth:50, padding:0, backgroundColor:"green" }}type="submit" onClick={() => doneOrder(key)} value="Done"/>
            </>
            
          :null
          }
          {(clientOrders.status==="requested" && (clientOrders.requestedBy===uuid || userRole==="owner" || userRole==="manager")) ?
            <>
          
              <input style={{ color:"white",marginLeft:3, marginRight:3, height:30, minWidth:60, padding:0 }}type="submit" onClick={() => cancelOrder(key)} value="Cancel"/>
          
            </>
            
          :null
          }
            
            
            <a href={`/${client_id}/${client_uid}/orders/${key}`}>
              <input style={{ color:"white", height:30, width:60, padding:0, marginLeft:3, marginRight:3, backgroundColor:"grey" }}type="submit" value="Details"/>
            </a>
            
            </div>
        </li>
    </div>
)

  return toDisplay

}


    const RenderOrders = () => {

      //applyFilters();

      if(Object.keys(clientOrders).length===0){
          return(
              <div className="client_list_container">
                  <div className="client_list">
                      <div className="client_list_item">
                          <h3 style={{fontSize:12, color:"white", padding: 20}}>Waiting for Orders...</h3>
                      </div>
                  </div>
              </div>
          )
      }else{
        //console.log(clientOrders)
              let displayed = []
              Object.keys(clientOrders).forEach((key) => {
                    // onAuthStateChanged(Authentication, (user) => { 
                    //  setUUID(user.uid)
                    let cards = applyFilters(clientOrders[key], key)
                          if(filters.includes("requested") && (clientOrders[key].status!=="complete" && clientOrders[key].status!=="cancelled" && clientOrders[key].status!=="declined") && clientOrders[key].requestedBy===uuid && displayed.includes(cards)===false){
                            displayed.push(cards)
                          }if(filters.includes("forMe") && (clientOrders[key].status!=="complete" && clientOrders[key].status!=="cancelled" && clientOrders[key].status!=="declined") && displayed.includes(cards)===false && clientOrders[key].service===userRole){
                            displayed.push(cards)
                          }if(filters.includes("pending") && clientOrders[key].status==="requested" && displayed.includes(cards)===false){
                            displayed.push(cards)
                          }if(filters.includes("all") && (clientOrders[key].status==="complete" || clientOrders[key].status==="cancelled" || clientOrders[key].status==="declined") && displayed.includes(cards)===false){
                            displayed.push(cards)
                          }

                    //    })
              });

              //console.log(reactElementToJSXString(<div>hey</div>))
              //console.log(displayed)

              //const uniqueOrders = Array.from(new Set(displayed));
  
              return(
                  <div style={{paddingTop:5}}>
                      {displayed}
                  </div>
              )
          }    
    }

    function openFilterModal() {setFilterModalIsOpen(true);}
    function afterOpenFilterModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }
    function closeFilterModal() {setFilterModalIsOpen(false);}


    const navAddOrder = (e) => {
        navigate(`/${client_id}/${client_uid}/add-order`);
    }

    const addFilterOptionToArray = (event) => {
      console.log(event)
      const { value } = event;
      if (event.checked) {
        setFilters([...filters, value]);
        console.log(value + " is checked")
        switch(value) {
          case "requested":
            setFilterRequestedChecked(true)
            break;
          case "forMe":
            setFilterForMeChecked(true)
            break;
          case "pending":
            setFilterPendingChecked(true)
            break;
          case "all":
            setFilterAllChecked(true)
            break;
        }
      } else {
        setFilters(filters.filter(val => val !== value));
        switch(value) {
          case "requested":
            setFilterRequestedChecked(false)
            break;
          case "forMe":
            setFilterForMeChecked(false)
            break;
          case "pending":
            setFilterPendingChecked(false)
            break;
          case "all":
            setFilterAllChecked(false)
            break;
        }
      }
      //console.log(filters)
      //applyFilters(ClientOrdersRaw)
    }

    const capitalize = (s) =>
    {
        let output = ""
        let words = s.split(" ");
        for(let word in words){
            output += words[word][0].toUpperCase() + words[word].slice(1) + " ";
        }
        return output
    }

  return (
    <>
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
        <Modal
        isOpen={filerModalIsOpen}
        onAfterOpen={afterOpenFilterModal}
        onRequestClose={closeFilterModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="btn" type="submit" onClick={closeFilterModal} style={{width:50, height: 20, padding:0, right:10, top:0, position: "absolute"}}>close</button>
        <h2 style={{color: '#eb5228'}}>Filter Orders</h2>
            <div style={{color:"white"}}>
            
            <div className="chckbx-group">
              <input type="checkbox" id="requested" value="requested" defaultChecked={filterRequestedChecked} onChange={(e) => addFilterOptionToArray(e.target)}/>
              <label htmlFor="requested" style={{color:"white"}}>Orders Requested By Me Today</label>
            </div>
            <div className="chckbx-group">
              <input type="checkbox" id="forMe" value="forMe" defaultChecked={filterForMeChecked} onChange={(e) => addFilterOptionToArray(e.target)}/>
              <label htmlFor="forMe" style={{color:"white"}}>Orders Sent To My Role Today</label>
            </div>
            <div className="chckbx-group">
              <input type="checkbox" id="pending" value="pending" defaultChecked={filterPendingChecked} onChange={(e) => addFilterOptionToArray(e.target)}/>
              <label htmlFor="pending" style={{color:"white"}}>All Pending Orders Today</label>
            </div>
            <div className="chckbx-group">
              <input type="checkbox" id="all" value="all" defaultChecked={filterAllChecked} onChange={(e) => addFilterOptionToArray(e.target)}/>
              <label htmlFor="all" style={{color:"white"}}>All Orders Today</label>
            </div>
            </div>
            
      </Modal>
     <div>
      <p style={{padding:20, paddingBottom:0, fontSize:15, color:"#eb5228"}} onClick={()=>setFilterModalIsOpen(true)}>filter</p>
        <RenderOrders />
     </div>
     <Fab
      //mainButtonStyles={mainButtonStyles}
      icon={<MdAdd />}
      event={'click'}
      alwaysShowTitle={true}
      mainButtonStyles={{ backgroundColor: "#eb5228", bottom: 0, right: 0 }}
      onClick={(e) => navAddOrder()}
      >
      </Fab>
    </>
        
  )
}

export default Orders