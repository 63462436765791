/* eslint-disable no-unused-vars */
import React, { useEffect, useState} from "react"
import NavBar from "../components/navbar";
import FloatingActionButton from "../components/fab";
import { useParams, useNavigate } from "react-router-dom";
import firebase from 'firebase/compat/app';

import { Authentication, database } from "../firebase-config";
import { RecaptchaVerifier, signInWithPhoneNumber, setPersistence, browserSessionPersistence, onAuthStateChanged} from "firebase/auth";
import { getDatabase, ref,onValue, child, get, set, push, serverTimestamp, increment, update, runTransaction } from "firebase/database";
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-modal";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import { MdAdd, MdCreate, MdCodeOff ,MdAddTask } from "react-icons/md";
import ManagerCheck from "../manager/managerCheck";
import { getDefaultEmulatorHost } from "@firebase/util";
import ClientDatabase from "../firebase-config-client";
import AdminDatabase from "../firebase-config-admin";

const customStyles = {
    overlay: {
        backgroundColor: 'rgba(21, 23, 25, 0.9)'
      },
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        height: '60%',
        backgroundColor: '#27282c',
        borderRadius: 10,
        border: 'none',
        paddingTop: 60,
        overflowY: "scroll",
    },
  };

function Menu(){

    const navigate = useNavigate();
    const {client_id, service, client_uid} = useParams();


    const [clientName, setClientName] = useState("");
    const [clientUID, setClientUID] = useState("");
    const [addOrderModalIsOpen, setAddOrderModalIsOpen] = useState(false);
    const [clientManager, setClientManager] = useState("+1");
    const [clientRoles, setClientRoles] = useState([])
    const [clientService, setClientService] = useState("")
    const [serviceMenu, setServiceMenu] = useState("")
    const [clientTeam, setClientTeam] = useState([])
    const [userRole,   setUserRole] = useState("")
    const [uuid, setUUID] = useState("")
    const [userName,   setUserName] = useState("")
    const [openAddMenuItemModal, setOpenAddMenuItemModal] = useState(false)
    const [cardSpaceForButtons, setCardSpaceForButtons] = useState(20)
    const [menuModalIsOpen, setMenuModalIsOpen] = useState(false)
    const [afterOpenMenuModal, setAfterOpenMenuModal] = useState(false)
    const [closeMenuModal, setCloseMenuModal] = useState(false)
    const [menuAddItemName, setMenuAddItemName] = useState("")
    const [menuAddItemPrice, setMenuAddItemPrice] = useState()
    const [menuAddItemDescription, setMenuAddItemDescription] = useState("")
    const [menuAddItemInStock, setMenuAddItemInStock] = useState("")


    const [orderServiceOptions, setOrderServiceOptions] = useState("none");
    const [menuOptions, setMenuOptions] = useState("none");
    const [orderNotes, setOrderNotes] = useState("");
    const [orderTotal, setOrderTotal] = useState(0);
    const [itemPrice, setItemPrice] = useState(0);
    const [itemName, setItemName] = useState("");


    const [addOrderDescription, setAddOrderDescription] = useState("");
    const [addOrderArray, setAddOrderArray] = useState([]);
    let [cart, setCart] = useState([])

    const errorAlert = (msg) => toast.error(msg,{
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });


    let localCart_store = localStorage.getItem("cart");


    useEffect(() => {
        //localStorage.removeItem("cart")
        console.log(localCart_store)
        let localCart = JSON.parse(localCart_store);
        //load persisted cart into state if it exists
        if (localCart) setCart(localCart);

        onAuthStateChanged(Authentication, (user) => {
            if (user) {
                setUUID(user.uid)
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                // ...
                getMenu();
                getTeam();
                //getAdmin();
            } else {
                // User is signed out
                // ...
                //console.log("User is signed out");
                //navigate("/login");
            }
        })
        
    },[])

    // const getAdmin = () => {
    //     const dbRef = ref(AdminDatabase);
    //     get(child(dbRef, `/admins`)).then((snapshot) => {
    //       if (snapshot.exists()) {
    //         console.log("Admins found")
    //         console.log(snapshot.val())
    //         //setUserRole("admin");
    //       }
    //     }).catch((error) => {
    //       console.error(error);
    //     });
    // }

    const getMenu = () => {

        const dbRef = ref(ClientDatabase(client_id, client_uid));
        get(child(dbRef, `/roles/${service}/menu`)).then((snapshot) => {
          if (snapshot.exists()) {
            setServiceMenu(snapshot.val());
          } else {
            console.log("Error: No menu found");
          }
        }).catch((error) => {
          console.error(error);
        });
    }

    const getTeam = () => {

    const dbRef = ref(ClientDatabase(client_id, client_uid));
      get(child(dbRef, `/team`)).then((snapshot) => {
        if (snapshot.exists()) {
          setClientTeam(snapshot.val());
          let team = snapshot.val()
            for(var x in team) {
                if(team[x].uid == Authentication.currentUser.uid) {
                    if (team[x].role == "manager" || team[x].role == "owner" || team[x].role == service){
                        setCardSpaceForButtons(56)
                        //console.log(cardSpaceForButtons)
                    }
                    setUserRole(team[x].role)
                    setUserName(team[x].name)
                    window.localStorage.setItem('user_role', team[x].role);
                }
            }
        } else {
          console.log("Error: No team found");
        }
      }).catch((error) => {
        console.error(error);
      });
    }

    const capitalize = (s) =>
    {
        let output = ""
        let words = s.split(" ");
        for(let word in words){
            output += words[word][0].toUpperCase() + words[word].slice(1) + " ";
        }
        return output
    }


    const RenderMenu = (e) => {


        if(Object.keys(serviceMenu).length == 0){
            return(
                <div className="client_list_container">
                    <div className="client_list">
                        <div className="client_list_item">
                            <h3 style={{fontSize:12, color:"white", padding: 20}}>Loading...</h3>
                        </div>
                    </div>
                </div>
            )
        }else{        
                    
                let displayed = []
                //console.log(serviceMenu)
                Object.keys(serviceMenu).forEach((key) => {

                    //if no menu items
                    if(serviceMenu == undefined || serviceMenu.length < 1){
                        displayed.push(
                            <div key={key} className="card shadow-1">
                            <li>                               
                                <p style={{fontSize:15}}><span style={{fontSize:12, color:"#eb5228"}}>{capitalize(key)}</span></p>                             
                                <p>No Menu Items Exist</p>
                            </li>
                        </div>
                        )
                    }else{
                        displayed.push(
                            <div key={key} className="card shadow-1" style={{paddingBottom:cardSpaceForButtons}}>
                                <li>                              
                                    <p style={{fontSize:15}}><span style={{fontSize:20, color:"#eb5228"}}>{capitalize(key)}</span></p>     
                                    {serviceMenu[key].desc?<p><span style={{fontSize:12}}>Description: </span>{serviceMenu[key].desc}</p>:null}                              
                                    <p><span style={{fontSize:12}}>Price: $</span>{serviceMenu[key].price}</p>
                                    <p style={{left:5, top:2, position:"absolute",fontSize:12}}><span></span>{serviceMenu[key].inStock?<></>:<>Out of Stock</>}</p>
                                    {serviceMenu[key].inStock?
                                    <>
                                    <input style={{right:8, top:"8px", position:"absolute", width: 100, height:30, padding:0}}type="submit" value="Add To Cart" onClick={(e) => (menuAddToCart(key))}/>
                                    </>
                                    :<p style={{right:8, top:"8px", position:"absolute", width: 100, height:30}}>Out of Stock</p>}
                                    
                                    {(userRole === "manager" || userRole === "owner" || userRole === service)?
                                        <>
                                        <input style={{right:8, bottom:"8px", position:"absolute", width: 100, height:30, padding:0}}type="submit" value="Delete" onClick={(e)=>(menuDeleteItem(key))}/>
                                        {serviceMenu[key].inStock?<input style={{right:116, bottom:"8px", position:"absolute", width: 100, height:30, padding:0}}type="submit" value="Out Of Stock" onClick={(e)=>(menuFlipAvailability(key, serviceMenu[key].inStock))}/>
                                        :<input style={{right:116, bottom:"8px", position:"absolute", width: 100, height:30, padding:0}}type="submit" value="Mark Available" onClick={(e)=>(menuFlipAvailability(key, serviceMenu[key].inStock))}/>}
                                        <input style={{right:224, bottom:"8px", position:"absolute", width: 100, height:30, padding:0}}type="submit" value="Edit" onClick={(e)=>(menuEditItem(key))}/>
                                        </>
                                    :null
                                }
                                </li>
                            </div>
                            )
                    }
    

                    

            });

                  return(
                    <div style={{paddingTop:5}}>
                        {displayed}
                    </div>
                )

            }
    }

    const menuAddToCart = (item) => {

        onAuthStateChanged(Authentication, (user) => {
            if (user) {
                let menu = serviceMenu
                //setClient_uid(user.uid)
                let cartItem = {
                    lineItem: item,
                    price: menu[item].price,
                    requestedBy: user.uid,
                    service: service,
                    status: "requested",
                    notes: "",
                }

                //create a copy of our cart state, avoid overwritting existing state
                let cartCopy = [...cart];
                
                
                cartCopy.push(cartItem)

                console.log(cartCopy)
                
                //update app state
                setCart(cartCopy)
                
                //make cart a string and store in local space
                let stringCart = JSON.stringify(cartCopy);
                localStorage.setItem("cart", stringCart)

                toast.success(`${item} Added to Cart`)
        
                //console.log(localStorage.getItem("cart"))
                
            } else {
                // No user is signed in.
            }
        })

  
    }

    const menuDeleteItem = (item) => {
        let menu = serviceMenu
        delete menu[item]
        setServiceMenu(menu)
        const dbRef = ref(ClientDatabase(client_id, client_uid));
        set(child(dbRef, `/roles/${service}/menu`), menu)
        .then(() => {
            // Data saved successfully!
            //docloseAddMenuItemModal();
            window.location.reload(false);
            })
            .catch((error) => {
            // The write failed...
            alert(error)
            });
    }

    const menuEditItem = (item) => {
        let menu = serviceMenu
        setMenuAddItemName(item)
        setMenuAddItemPrice(parseFloat(menu[item].price))
        setMenuAddItemDescription(menu[item].desc)
        setMenuAddItemInStock(menu[item].inStock)
        doopenAddMenuItemModal()
    }

    const menuFlipAvailability = (item, availableBool) => {
        let menu = serviceMenu
        menu[item].inStock = !availableBool
        setServiceMenu(menu)
        const dbRef = ref(ClientDatabase(client_id, client_uid));
        set(child(dbRef, `/roles/${service}/menu`), menu)
        .then(() => {
            // Data saved successfully!
            //docloseAddMenuItemModal();
            window.location.reload(false);
            })
            .catch((error) => {
            // The write failed...
            alert(error)
            });

    }


    function doopenAddMenuItemModal() {setOpenAddMenuItemModal(true);}
    function afterOpenRoleModal() {
    // references are now sync'd and can be accessed.
    //subtitle.style.color = '#f00';
    }
    function docloseAddMenuItemModal() {setOpenAddMenuItemModal(false);}

    const RenderMenuAdd = (e) => {
            
            let showAlllMenus = false
    
            if (userRole === "manager" || userRole === "owner" || userRole === service) {
               // ManagerCheck();
                return(
                    <>
                            <Fab
                                //mainButtonStyles={mainButtonStyles}
                                icon={<MdAdd />}
                                event={'click'}
                                alwaysShowTitle={true}
                                mainButtonStyles={{ backgroundColor: "#eb5228", bottom: 0, right: 0 }}
                                onClick={doopenAddMenuItemModal}
                                >
                            </Fab>
                    </>
                )
            }
    }

    const handleMenuModalSubmit = (e) => {
        //validate
        if(menuAddItemName.length < 1){
            alert("Please enter a menu item name")
            return
        }if (menuAddItemPrice.length < 1){
            alert("Please enter a menu item price")
            return
        }if (typeof menuAddItemPrice === 'number'){
            alert("The price must be a number")
            return
        }if (menuAddItemInStock.length < 1){
            alert("Please enter a menu item in stock")
            return
        }
        //console.log(menuAddItemInStock)
        addMenuItem(e)

    }

    const addMenuItem = (e) =>{
        e.preventDefault()

        let role = service.toLowerCase();

            set(ref(ClientDatabase(client_id, client_uid), `/roles/${role}/menu/${menuAddItemName}`), {inStock: menuAddItemInStock, price: parseFloat(menuAddItemPrice), desc: menuAddItemDescription})
            .then(() => {
            // Data saved successfully!
            docloseAddMenuItemModal();
            window.location.reload(false);
            })
            .catch((error) => {
            // The write failed...
            alert(error)
            });
    }





  return (
    <>
     <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        />
     <Modal
        isOpen={openAddMenuItemModal}
        onAfterOpen={afterOpenMenuModal}
        onRequestClose={docloseAddMenuItemModal}
        style={customStyles}
        contentLabel="Menu Add Modal"
      >
        <button className="btn" type="submit" onClick={docloseAddMenuItemModal} style={{width:50, height: 20, padding:0, right:10, top:0, position: "absolute"}}>close</button>
        <h2 style={{color: '#eb5228'}}>Add Menu</h2>
            <div className="inputBox">
                <input type="text" required value={menuAddItemName} onChange={(e) => setMenuAddItemName(e.target.value)}/>
                <span>Line Item Name</span>
                <i></i>
            </div>
            <div className="inputBox">
                <input type="tel" required value={menuAddItemPrice} onChange={(e) => setMenuAddItemPrice(e.target.value)} 
                onKeyDown={(event) => {
                    if(event.key !== "Backspace" && event.key !== "Delete"){
                        if (!/[0-9\.]/.test(event.key)) {
                            console.log(event.key)
                            event.preventDefault();
                        }
                    }
                  }}/>
                <span>Price</span>
                <i></i>
            </div>
            <div className="inputBox">
                <input type="text" required value={menuAddItemDescription} onChange={(e) => setMenuAddItemDescription(e.target.value)}/>
                <span>Description (Optional)</span>
                <i></i>
            </div>
            <div className="inputBox"
            onChange={(e) => {
                //console.log(e.target.value)
                setMenuAddItemInStock((e.target.value === 'true'))
            }}
            >
                <p style={{color: '#eb5228'}}>Mark Available?</p>
                <div className="chckbx-group">
                    <input type="radio" id="stock_true" value="true" name="stock" />
                    <label htmlFor="stock_true" style={{color:"white"}}>Available</label>
                </div>
                <div className="chckbx-group">
                    <input type="radio" id="stock_false" value="false" name="stock" />
                    <label htmlFor="stock_false" style={{color:"white"}}>Out of stock</label>
                </div>
            </div>
            
            
            <div className="links">
            </div>
            <button className="btn" type="submit" onClick={(e) => handleMenuModalSubmit(e)} >Submit</button>
      </Modal>
     <div className="container">
        <div className="register_container">
        <h2 style={{color: '#eb5228'}}>{capitalize(service)} Menu</h2>
           <RenderMenu />
           <RenderMenuAdd />
        </div>
    </div>
    </>
        
  )
}

export default Menu